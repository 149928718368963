<div class="requirement-sp7">
  <!-- タイトル -->
  <div class="control-top border">
    <div class="text-center">
      {{ selectedAtFuncTitle }}
    </div>
  </div>
  <!-- リスト -->
  <div class="control-bottom">
    <div class="control-left border">
      <div class="list-div">
        <cstm-table
          fixedHeader
          useStripeStyle
          hasTabs
          type="simple"
          @left-btn-click="handleLeftBtnClick"
          @right-btn-click="handleRightBtnClick"
          :items="items"
          :columns="columns"
          :disabled="btnDisabled.ac"
        />
      </div>
    </div>
    <div class="control-right">
      <div class="control-text border">
        {{ message }}
      </div>
      <b-button :disabled="drvBtnDisabled" variant="primary" @click="driveBtn()" ref="drvBtn">
        {{ driveBtnLabel }}
      </b-button>
    </div>
  </div>
  <processing-view :processing="displayProcessing" />
</div>
