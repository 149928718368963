<div class="max-height data-monitor-container">
  <!-- 項目選択 -->
  <div v-show="showSelect">
    <data-monitor-select
      ref="data_monitor_select"
      :isUseCache="isUseCache"
      :isDataMonitor="isDataMonitor"
      :isSpecial="isSpecial"
      @start-execute="startExecute"
    />
  </div>
  <!-- 計測 -->
  <div v-if="!showSelect && (Object.keys(conversionList).length > 0)">
    <div :class="getAreaStyle()">
      <data-monitor-execute
        class="data-monitor"
        ref="data_monitor_execute"
        :inExecution="inExecution"
        :inPlaying="inPlaying"
        :conversionList="conversionList"
        :dataMonitorList="dataMonitorList"
        :selectedFunctionList="selectedFuncs"
        :selectedFunctionHash="selectedFunctionHash"
        :completeResolve="completeResolve"
        :baseTime="baseTime"
        :measurementUuid="measurementUuid"
        :apiToken="apiToken"
        :endpoint="endpoint"
        @data-total-count="notifyDataTotalCount"
        @toggle-playing="togglePlaying"
        @change-selected-funcs="changeSelectedFuncs"
        @stop-monitor-error-terminate="stopMonitorErrorTerminate"
        @show-disconnected-server-error="showCommonError"
      />
    </div>
  </div>
  <processing-view :processing="displayProcessing" />
</div>
