<!-- EOP点検ダイアログ -->
<b-modal v-model="modal_work_support_022" size="lg" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 警告 -->
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_warning') }}
      </template>

      <!-- 確認 -->
      <template v-if="phase === 'offWait' ||
                      phase === 'orderOff' || 
                      phase === 'orderOn' || 
                      phase === 'orderInspection' || 
                      phase === 'orderTemperature' || 
                      phase === 'orderSelectP' || 
                      phase ===  'orderIgSwOffOn' || 
                      phase ===  'runOrder' || 
                      phase ===  'runWait' ||
                      phase ===  'stopOrder'
                      ">
        {{ $t('message.common_title_confirmation') }}
      </template>

      <!-- エラー -->
      <template v-if="phase === 'communicationError' || 
                      phase === 'timeOver' ||
                      phase === 'connectionFailed' 
                      ">
        {{ $t('message.common_title_error') }}
      </template>

    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px; white-space: pre-line">

        <!-- 開始 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12" v-html="$t(`individual.label_init_order`)"></div>
          </div>
        </template>

        <!-- スイッチOFFのまま待機メッセージ -->
        <template v-if="phase === 'offWait'">
          <div class="row no-gutters">
            <div class="col-12">
              {{ $t(`individual.label_wait_ig_sw_off`) }}
            </div>
          </div>
        </template>

        <!-- スイッチOFF指示メッセージ-->
        <template v-if="phase === 'orderOff'">
          <div class="row no-gutters">
            <div class="col-12">
              {{ $t(`individual.label_order_ig_sw_off`) }}
            </div>
          </div>
        </template>

        <!-- スイッチON指示メッセージ -->
        <template v-if="phase === 'orderOn'">
          <div class="row no-gutters">
            <div class="col-12">
              {{ $t(`individual.label_order_ig_sw_on`) }}
            </div>
          </div>
        </template>

        <!-- 故障検出表示-->
        <template v-if="phase === 'orderInspection'">
          <div class="row no-gutters">
            <div class="col-12" v-html="$t(`individual.label_fault_detected`)"></div>
          </div>
        </template>

        <!-- 暖機・冷却指示-->
        <template v-if="phase === 'orderTemperature'">
          <div class="row no-gutters">
            <div class="col-12" v-html="temperatureMsg"></div>
          </div>
        </template>

        <!-- Pレンジセレクト実施-->
        <template v-if="phase === 'orderSelectP'">
          <div class="row no-gutters">
            <div class="col-12">
              {{ $t(`individual.label_order_select_P`) }}
            </div>
          </div>
        </template>

        <!-- エンジン動作検出-->
        <template v-if="phase === 'orderIgSwOffOn'">
          <div class="row no-gutters">
            <div class="col-12" v-html="$t(`individual.label_order_ig_sw_off_on`)"></div>
          </div>
        </template>

        <!-- 実行(駆動ボタン表示) -->
        <template v-if="phase === 'runOrder'">
          <div class="w-100 text-center" style="margin-top: 20px;">
            <b-button size="sm" variant="primary" @click="clickDrive" class="mx-2" style="white-space: nowrap">
              {{ $t(`individual.label_button_driving`) }}
            </b-button>
          </div>
        </template>

        <!-- 実行監視中 -->
        <template v-if="phase === 'runWait'">
          <div class="col-12" v-html="displayMsg"></div>
        </template>

        <!-- EOPの通信エラーメッセージ-->
        <template v-if="phase === 'communicationError'">
          <div class="row no-gutters">
            <div class="col-12" v-html="$t(`individual.label_error_communication`)"></div>
          </div>
        </template>

        <!-- タイムアウトエラーメッセージ-->
        <template v-if="phase === 'timeOver'">
          <div class="row no-gutters">
            <div class="col-12">
              {{ $t(`individual.label_time_over`) }}
            </div>
          </div>
        </template>

        <!-- 中止エラーメッセージ-->
        <template v-if="phase === 'stopOrder'">
          <div class="row no-gutters">
            <div class="col-12">
              {{ $t(`individual.label_aborts_eop`) }}
            </div>
          </div>
        </template>

        <!-- 異常終了(共通エラー) -->
        <template v-if="phase === 'connectionFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 開始メッセージ：実行のOKボタン,中止表示のCancelボタン -->
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" @click="clickShowStop" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="executeIgOff" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 中止を表示するCancelボタン(readwait) -->
      <!-- スイッチOFF指示／スイッチON指示／Pレンジセレクト　-->
      <template v-if="phase === 'orderOff' ||
                            phase === 'orderOn' ||
                            phase === 'orderSelectP'
                            ">
        <b-button size="sm" variant="outline-primary" @click="stopReadData" class="mx-2" :disabled="isCanceled">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>

      <!-- 中止を表示するCancelボタン(read) -->
      <!-- 暖機冷却指示 -->
      <template v-if="phase === 'orderTemperature'">
        <b-button size="sm" variant="outline-primary" @click="clickShowStop" class="mx-2" :disabled="isCanceled">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>

      <!-- 中止を表示するOKボタン -->
      <!-- 故障検出点検指示／タイムオーバー-->
      <!-- EOPの通信エラーメッセージ-->
      <template v-if="phase === 'orderInspection' ||
                      phase === 'communicationError' ||
                      phase === 'timeOver'
                      ">
        <b-button size="sm" variant="primary" @click="clickShowStop" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- エンジン動作メッセージ-->
      <template v-if="phase === 'orderIgSwOffOn'">
        <b-button size="sm" variant="outline-primary" @click="clickShowStop" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="clickReEngine" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 中止 -->
      <template v-if="phase === 'stopOrder'">
        <b-button size="sm" variant="primary" @click="exit" class="mx-2" :disabled="isLoading || isCanceled">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 共通エラー -->
      <template v-if="phase === 'connectionFailed'">
        <b-button size="sm" variant="primary" @click="exit" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>

</b-modal>