<b-modal v-model="modal_requirement_114" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- ファイル選択,　バリアントデータ判定, 停止画面 -->
      <template v-if="phase === 'inputFile' || phase === 'variantJudgement' || 
        phase === 'variantSuccess'|| phase === 'registered'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 書込み中, 実行中 -->
      <template v-if="phase === 'start' || phase === 'write' || phase === 'inProgress'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 不正フォーマット表示, バリアントデータ登録済表示,
        車輪速超過表示, パラメータ選択不可表示, 書込み失敗表示, 補用品取付け確認表示, エラー画面 -->
      <template v-if="phase === 'inputFileError'  || phase === 'speedError' ||
        phase === 'selectError' || phase === 'writeError' || phase === 'variantError' ||
        phase === 'cancel' || phase === 'installationError' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行中 -->
        <template v-if="phase === 'start'"></template>
        <!-- ファイル選択 -->
        <template v-if="phase === 'inputFile'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.label_show_select') }}</div>
          </div>
        </template>
        <!-- 不正フォーマット表示 -->
        <template v-if="phase === 'inputFileError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_5`) }}</div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'inProgress'"></template>
        <!-- 書込み中 -->
        <template v-if="phase === 'write'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_2`) }}</div>
          </div>
        </template>
        <!-- バリアントデータ判定 -->
        <template v-if="phase === 'variantJudgement'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_3`) }}</div>
          </div>
        </template>
        <!-- 停止画面 -->
        <template v-if="phase === 'variantSuccess'">
          <div class="row no-gutters">
            <div class="w-100">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_table_applied_model`) }}</div>
              </div>
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table
                  id="cstm-table"
                  useStripeStyle
                  hasTabs
                  useSort
                  initSortDir="acs"
                  currentSortkey="itemName"
                  :items="modelBodyItems"
                  :columns="modelTableColumns"
                  type="simple"
                >
                </cstm-table>
              </div>
              <div class="text-left align-middle mt-2">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_table_grade`) }}</div>
              </div>
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table
                  id="cstm-table"
                  useStripeStyle
                  hasTabs
                  useSort
                  initSortDir="acs"
                  currentSortkey="itemName"
                  :items="gradeBodyItems"
                  :columns="gradeTableColumns"
                  type="simple"
                >
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- バリアントデータ登録済表示 -->
        <template v-if="phase === 'registered'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 車輪速超過表示 -->
        <template v-if="phase === 'speedError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_6`) }}</div>
          </div>
        </template>
        <!-- パラメータ選択不可表示 -->
        <template v-if="phase === 'selectError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_7`) }}</div>
          </div>
        </template>
        <!-- 書込み失敗表示 -->
        <template v-if="phase === 'writeError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_8`) }}</div>
          </div>
        </template>
        <!-- バリアントデータ不正表示 -->
        <template v-if="phase === 'variantError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_9`) }}</div>
          </div>
        </template>
        <!-- 補用品取付け確認表示 -->
        <template v-if="phase === 'installationError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_114_TXT_11`) }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t('individual.error_communication_error') }}</div>
          </div>
        </template>
        <!-- キャンセル -->
        <template v-if="phase === 'cancel'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_114_TXT_10`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- ファイル選択 -->
      <template v-if="phase === 'inputFile'">
        <b-button size="sm" variant="primary" @click="onInputFileSelectClicked" class="mx-2" :disabled="isLoading">{{
          $t('individual.button_work_support_select') }}
        </b-button>
      </template>
      <!-- バリアントデータ登録済表示 -->
      <template v-if="phase === 'registered'">
        <b-button size="sm" variant="primary" @click="registeredOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- バリアントデータ判定 -->
      <template v-if="phase === 'variantJudgement'">
        <b-button size="sm" variant="primary" @click="variantJudgementOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止画面 -->
      <template v-if="phase === 'variantSuccess'">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="onCancelClicked"
          class="mx-2"
          :disabled="isLoading"
        >
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onStopOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー画面 -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2" :disabled="isLoading">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>