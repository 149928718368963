/* eslint-disable max-lines */

const Constants = require('@/common/nodejs/constants');

// Front Side Constant class

export default Object.freeze({

  // Domain Type
  SBR_DOMAIN: 'sbr',
  SCI_DOMAIN: 'sci',
  SOA_DOMAIN: 'soa',
  THIRD_PARTY_DOMAIN: 'third_party',

  // product environment
  MULTI_DOMAIN_MAP: {
    'ssm.subaru.co.jp': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'ca.ssm.subaru.co.jp': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'ssm5.subaru.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber.ssm5.subaru.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'd15va41apjrm5c.cloudfront.net': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'gihon-ssm.subaru.co.jp': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  // TEST environment
  TEST_MULTI_DOMAIN_MAP: {
    'test1.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'test1.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-test1.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-test1.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'gihon-test1.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under'
    },
  },

  TEST2_MULTI_DOMAIN_MAP: {
    'test2.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'test2.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-test2.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-test2.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'gihon-test2.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under'
    },
  },

  TEST3_MULTI_DOMAIN_MAP: {
    'test3.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'test3.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-test3.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-test.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'gihon-test3.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under'
    },
  },

  // dev1 environment
  DEV_MULTI_DOMAIN_MAP: {
    'dev.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'gihon-dev.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under'
    },
  },

  // dev2 environment
  DEV2_MULTI_DOMAIN_MAP: {
    'dev2.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev2.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev.sbr-dev2.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev2.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'gihon-dev2.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under'
    }
  },

  // dev3 environment
  DEV3_MULTI_DOMAIN_MAP: {
    'dev3.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev3.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev3.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev3.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  // dev4 environment 
  DEV4_MULTI_DOMAIN_MAP: {
    'dev4.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev4.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev4.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev4.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'd1r0yee6nzclc8.cloudfront.net': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'gihon-dev3.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under'
    },
  },

  // APIの実行先
  ADMIN: 'admin',
  REPRO: 'repro',
  MASTER: 'master',
  MAINT: 'maintenance',
  GPLM: 'gplm',
  MAC: 'mac',
  IMB: 'imb',
  WSP: 'wsp',
  VCH: 'vch',
  CST: 'cst',
  CNC: 'cnc',
  DMAT: 'dmat',
  DIAG: 'dig',

  // 対応言語の一覧(表示文言は各言語で表示するため固定)
  LANGUAGE_LIST: [
    { value: 'ja', label: '日本語' },
    { value: 'en', label: 'English' },
    { value: 'zh', label: '中文' },
    { value: 'it', label: 'Italiano' },
    { value: 'es', label: 'Español' },
    { value: 'fr', label: 'Français' },
    { value: 'de', label: 'Deutsch' },
    { value: 'ru', label: 'Русский' }
  ],

  // ユーザーに紐づくキャッシュ情報のキー
  USER_CACHE_PFC_SEARCH_KEY_APPLOVAL: 'approvalSearchKey',
  USER_CACHE_REPRO_SYSTEM_TRANSLATION: 'reproSystemTranslation',
  USER_CACHE_REPRO_FAILUER_INFO: 'reproFailureInformation',
  USER_CACHE_REPRO_CHASSIS_AREA_NAME: 'reproChassisAreaName',
  USER_CACHE_REPRO_TARGET_INFO: 'reproTargetInformation',

  // ナビゲーションバーの表示種別
  // ナビゲーションバー 表示なし
  NAVIGATION_NONE: 'none',
  // ナビゲーションバー 表示あり
  NAVIGATION_DISPLAY: 'display',
  // ナビゲーションバー無効(disabled)
  NAVIGATION_DISABLED: 'disabled',

  // 管理画面の router:name と navigationの文言 MAP
  MANAGE_NAVIGATION_RESOURCES: {
    home: 'message.header_home_menu',
    userList: 'message.header_main_user_list',
    importCompanyUser: 'message.button_user_table_import',
    userCreate: 'message.navigation_user_register',
    userEdit: 'message.navigation_user_edit',
    companyList: 'message.header_company_shop_info',
    companySearch: 'message.header_company_search',
    shopSearch: 'message.header_shop_search',
    companyCreate: 'message.header_company_create',
    companyEdit: 'message.header_company_edit',
    shopCreate: 'message.header_shop_create',
    shopEdit: 'message.header_shop_edit',
    pfcList: 'message.header_pfc_list',
    pfcUpload: 'message.header_pfc_upload',
    pfcDetails: 'message.header_pfc_details',
    pfcApprovals: 'message.header_pfc_approvals',
    pfcLoad: 'message.header_pfc_config',
    maintenanceMain: 'message.header_maint_management',
    countryList: 'message.label_maint_list_item',
    countryCreate: 'message.header_country_create',
    countryEdit: 'message.header_country_edit',
    targetUnitList: 'message.label_maint_list_item',
    targetUnitCreate: 'message.header_targetunit_create',
    targetUnitEdit: 'message.header_targetunit_edit',
    engineList: 'message.label_maint_list_item',
    engineCreate: 'message.header_engine_create',
    engineEdit: 'message.header_engine_edit',
    intakeList: 'message.label_maint_list_item',
    intakeCreate: 'message.header_intake_create',
    intakeEdit: 'message.header_intake_edit',
    transmissionList: 'message.label_maint_list_item',
    transmissionCreate: 'message.header_tm_create',
    transmissionEdit: 'message.header_tm_edit',
    reproList: 'message.label_maint_list_item',
    reproCreate: 'message.header_repro_create',
    reproEdit: 'message.header_repro_edit',
    vehicleInfoList: 'message.label_maint_list_item',
    vehicleInfoCreate: 'message.header_vehicleinfo_create',
    vehicleInfoEdit: 'message.header_vehicleinfo_edit',
    importExport: 'message.header_import_export',
    importCommon: 'message.header_import_export',
    importCountry: 'message.header_imex_country_import',
    importCompany: 'message.header_imex_company_import',
    importShop: 'message.header_imex_shop_import',
    importAffiliated: 'message.header_imex_affiliated_import',
    importUser: 'message.header_imex_user_import',
    importTranslation: 'message.header_imex_translation_import',
    vehicleModelNameList: 'message.label_maint_list_item',
    vehicleModelNameCreate: 'message.header_vehicle_model_name_create',
    vehicleModelNameEdit: 'message.header_vehicle_model_name_edit',
    canidList: 'message.label_maint_list_item',
    canidCreate: 'message.header_canid_create',
    canidEdit: 'message.header_canid_edit',
    groupAddition: 'message.header_group_addition',
    gplmLink: 'message.header_maint_gplm_linkage',
    itemList: 'message.label_maint_list_item',
    reproFileRegistration: 'message.header_maint_gplm_linkage',
    reproFileRegistrationHistory: 'message.header_maint_gplm_linkage',
    unitFilterList: 'message.header_unit_filter_linkage_modal',
    planList: 'message.navigation_plan_list',
    planCreate: 'message.navigation_plan_register',
    planEdit: 'message.navigation_plan_edit',
    monitoring: 'message.navigation_monitoring',
    variantDataLink: 'message.header_variant_data_file',
    variantDataUpload: 'message.header_variant_data_file',
    variantDataRegistrationHistory: 'message.header_variant_data_file',
    eventLog: 'message.label_home_event_log',
    chinaAreaConfiguration: 'message.header_maint_gplm_linkage',
  },

  // 実行画面の router:name と navigationの文言 MAP
  EXECUTE_NAVIGATION_RESOURCES: {
    home: 'message.header_home_menu',
    blank: '',
    systemTable: 'message.label_function_menu_reprogramming',
    systemInformation: 'message.header_vehicle_system_information',
    attention: 'message.header_vehicle_system_repro',
    execution: 'message.header_vehicle_system_repro',
    result: 'message.header_repro_result',
    suSystemTable: 'message.label_function_menu_reprogramming',
    usbVerificationFailed: 'message.header_vehicle_system_information',
    menu: 'message.header_function_menu',
    diagProjectList: 'message.header_measured_data',
    dtcTable: 'message.label_function_menu_all_dtc',
    individualDiagCode: 'individual.individual_diagnosis_function_diag_code',
    individualCancelCode: 'individual.individual_diagnosis_function_cancel_code',
    individualDataMonitor: 'individual.individual_diagnosis_function_data_monitor',
    individualVehicleControlHistory: 'individual.individual_diagnosis_function_vehicle_control_history',
    individualActiveTest: 'individual.individual_diagnosis_function_active_test',
    individualWorkSupport: 'individual.individual_diagnosis_function_work_support',
    individualCustomize: 'individual.individual_diagnosis_function_customize',
    diagProjectDetails: 'message.header_measured_data',
    dataRecollection: 'message.header_measures_recollection',
    macKey: 'message.label_function_menu_mac_key_registration',
    immobilizer: 'message.label_function_menu_imobi_registration',
    specialFeatureEcuTable: 'message.label_function_menu_special_feature',
    specialFeatureSelect: 'message.label_function_list',
    realWorldDataTable: 'message.label_function_menu_vehicle_information',
    spWorkSupport: 'individual.individual_diagnosis_function_work_support',
    spDiagCode: 'individual.individual_diagnosis_function_diag_code',
    spCancelCode: 'individual.individual_diagnosis_function_cancel_code',
    spDataMonitor: 'individual.individual_diagnosis_function_data_monitor',
    individualEcuTable: 'individual.label_menu_individual_diagnosis',
    individualSelect: 'message.label_function_list',
  },

  // 所属の判別
  ASSIGNED_COMPANY: Constants.USER_TYPE_COMPANY,
  ASSIGNED_SHOP: Constants.USER_TYPE_SHOP,

  // 会社種別
  SBR_COMPANY: 'sbr_company',
  SOA_COMPANY: 'soa_company',
  SCI_COMPANY: 'sci_company',
  NASI_COMPANY: 'nasi_company',
  SOA_THIRD_PARTY: 'soa_thirdparty',
  SCI_THIRD_PARTY: 'sci_thirdparty',

  // リージョン情報
  REGION_DOMESTIC: Constants.COUNTRY_GROUP_DOMESTIC,
  REGION_NORTH_AMERICA: Constants.COUNTRY_GROUP_NORTH_AMERICA,
  REGION_EUROPE: Constants.COUNTRY_GROUP_EUROPE,

  // 権限グループ一覧
  GROUP_SYSTEM: Constants.USER_GROUP_SYSTEM_ADMIN,
  GROUP_SERVICE: Constants.USER_GROUP_SERVICE_ADMIN,
  GROUP_PFC: Constants.USER_GROUP_REPRO_REGISTRANT,
  GROUP_GENERAL: Constants.USER_GROUP_USERS,
  GROUP_REPRO_FILE_ADMIN: Constants.USER_GROUP_REPRO_FILE_ADMIN,
  GROUP_REPRO_FILE_TESTER: Constants.USER_GROUP_REPRO_FILE_TESTER,
  GROUP_USER_ADMIN: Constants.USER_GROUP_USER_ADMIN,
  GROUP_VARIANT_DATA_FILE_ADMIN: Constants.USER_GROUP_VARIANT_DATA_FILE_ADMIN,
  GROUP_EVENT_LOG_ADMIN: Constants.USER_GROUP_EVENT_LOG_ADMIN,

  // 利用者の検索条件（廃止区分）
  SEARCH_USER_ACTIVE: 'active',
  SEARCH_USER_ABOLISHED_ALL: 'all',
  SEARCH_USER_ABOLISHED_MANUAL: 'manual',
  SEARCH_USER_ABOLISHED_AUTO: 'auto',

  // サービス名一覧
  SERVICE_REPRO: Constants.SERVICE_NAME_REPRO,
  SERVICE_MANAGE: Constants.SERVICE_NAME_MANAGE,

  // バリデーション条件
  SSM5_INVALID_CHAR_PATTERN: /^(?!.*(¥|\\|")).*$/,
  USER_ID_MAX_LEN: Constants.USER_ID_MAXIMUM_LENGTH,
  USER_EMAIL_MAX_LEN: Constants.USER_EMAIL_MAXIMUM_LENGTH,
  USER_FIRSTNAME_MAX_LEN: Constants.USER_FIRSTNAME_MAXIMUM_LENGTH,
  USER_LASTNAME_MAX_LEN: Constants.USER_LASTNAME_MAXIMUM_LENGTH,
  USER_INFO_MAX_LEN_64: Constants.USER_INFO_MAXIMUM_LENGTH_64,
  USER_INFO_MAX_LEN_256: Constants.USER_INFO_MAXIMUM_LENGTH_256,
  USER_PASSWORD_MIN_LEN: Constants.USER_PASSWORD_MINIMUM_LENGTH,
  USER_PASSWORD_MAX_LEN: Constants.USER_PASSWORD_MAXIMUM_LENGTH,
  COMPANYNAME_MAX_LEN: Constants.COMPANYNAME_MAXIMUM_LENGTH,
  DEPARTMENTNAME_MAX_LEN: Constants.DEPARTMENTNAME_MAXIMUM_LENGTH,
  EMAIL_PATTERN: Constants.EMAIL_PATTERN_REGX,
  URL_PATTERN: Constants.URL_PATTERN_REGX,
  COMPANY_CODE_PATTERN: Constants.COMPANY_CODE_PATTERN,
  SHOP_CODE_PATTERN: Constants.SHOP_CODE_PATTERN,
  SSM_COUNTRY_CODE_PATTERN: Constants.SSM_COUNTRY_CODE_PATTERN,
  TARGETUNIT_SYSTEMNAME_PATTERN: Constants.TARGETUNIT_SYSTEMNAME_PATTERN,
  VEHICLEINFO_CODE_PATTERN: Constants.VEHICLEINFO_CODE_PATTERN,
  VEHICLEINFO_MODEL_PATTERN: Constants.VEHICLEINFO_MODEL_PATTERN,
  VEHICLEINFO_ANNUAL_IMPROVEMENTS_PATTERN: Constants.VEHICLEINFO_ANNUAL_IMPROVEMENTS_PATTERN,
  VEHICLEINFO_ANNUAL_CLASSIFICATION_PATTERN: Constants.VEHICLEINFO_ANNUAL_CLASSIFICATION_PATTERN,
  VEHICLEINFO_MODEL_YEAR_PATTERN: Constants.VEHICLEINFO_MODEL_YEAR_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER1_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER1_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER1_A_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER1_A_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER2_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER2_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER2_A_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER2_A_PATTERN,
  VEHICLEINFO_APPLIED_MODEL_PATTERN: Constants.VEHICLEINFO_APPLIED_MODEL_PATTERN,
  VEHICLEINFO_APPLIED_MODEL_A_PATTERN: Constants.VEHICLEINFO_APPLIED_MODEL_A_PATTERN,
  VEHICLEINFO_VIN_PATTERN: Constants.VEHICLEINFO_VIN_PATTERN,
  VEHICLEINFO_VIN_A_PATTERN: Constants.VEHICLEINFO_VIN_A_PATTERN,
  VEHICLEINFO_PUB_NO_PATTERN: Constants.VEHICLEINFO_PUB_NO_PATTERN,
  VEHICLEINFO_VEHICLE_MODEL_PATTERN: /^[a-zA-Z0-9 +]*$/,
  CANIDINFO_CANID_PATTERN: Constants.CANIDINFO_CANID_PATTERN,

  PFC_CVN_MAX_LEN: Constants.PFC_CVN_MAX_LEN,
  PFC_ENCRYPTION_MAX_LEN: Constants.PFC_ENCRYPTION_MAX_LEN,
  PFC_SERVICE_NEWS_MAX_LEN: Constants.PFC_SERVICE_NEWS_MAX_LEN,
  PFC_SUPPLEMENTARY_MAX_LEN: Constants.PFC_SUPPLEMENTARY_MAX_LEN,
  PFC_REMARKS_MAX_LEN: Constants.PFC_REMARKS_MAX_LEN,
  PFC_DETAILS_MAX_LEN: Constants.PFC_DETAILS_MAX_LEN,
  PFC_NOTES_MAX_LEN: Constants.PFC_NOTES_MAX_LEN,
  PFC_DEALER_SERVICE_NEWS_MAX_LEN: Constants.PFC_DEALER_SERVICE_NEWS_MAX_LEN,
  PFC_CVN_PATTERN: Constants.PFC_CVN_PATTERN,
  // 現在使用していないためコメントアウトする
  // PFC_ENCRYPTION_PATTERN: Constants.PFC_ENCRYPTION_PATTERN,
  PFC_SERVICE_NEWS_PATTERN: Constants.PFC_SERVICE_NEWS_PATTERN,
  PFC_DEALER_SERVICE_NEWS_PATTERN: Constants.PFC_DEALER_SERVICE_NEWS_PATTERN,

  // 会社区分のグループ
  COMPANY_GROUP_DISTRIBUTOR: Constants.COMPANY_GROUP_DISTRIBUTOR,
  COMPANY_GROUP_TRADING: Constants.COMPANY_GROUP_TRADING_COMPANY,
  COMPANY_GROUP_GENERAL: Constants.COMPANY_GROUP_MAINTENACE_COMPANY,
  COMPANY_GROUP_SBR: Constants.COMPANY_GROUP_SBR,
  COMPANY_GROUP_AREA: Constants.COMPANY_GROUP_AREA,
  COMPANY_GROUP_OTHER: Constants.COMPANY_GROUP_OTHER,
  COMPANY_GROUP_REGION: Constants.COMPANY_GROUP_REGION,

  COMPANY_DIRECT: Constants.COMPANY_DIRECT,
  COMPANY_INDIRECT: Constants.COMPANY_INDIRECT,
  COMPANY_HYPHEN: Constants.COMPANY_HYPHEN,

  COMPANY_ID_MAX_LEN: Constants.COMPANY_ID_MAX_LEN,
  COMPANY_NAME_MAX_LEN: Constants.COMPANY_NAME_MAX_LEN,
  COMPANY_ADDRESS_MAX_LEN: Constants.COMPANY_ADDRESS_MAX_LEN,
  COMPANY_BULLETINURL_MAX_LEN: Constants.COMPANY_BULLETINURL_MAX_LEN,
  COMPANY_REMARKS_MAX_LEN: Constants.COMPANY_REMARKS_MAX_LEN,

  // 国内の店舗区分
  SHOP_GROUP_JP_HEAD_OFFICE: Constants.SHOP_GROUP_JP_HEAD_OFFICE,
  SHOP_GROUP_JP_DEALER: Constants.SHOP_GROUP_JP_DEALER,
  SHOP_GROUP_JP_TRADE_SALES: Constants.SHOP_GROUP_JP_TRADE_SALES,
  SHOP_GROUP_JP_EXCEPT_DISTRIBUTOR: Constants.SHOP_GROUP_JP_EXCEPT_DISTRIBUTOR,
  // 北米の店舗区分
  SHOP_GROUP_NA_DIST_HQ: Constants.SHOP_GROUP_NA_DIST_HQ,
  SHOP_GROUP_NA_DEALER: Constants.SHOP_GROUP_NA_DEALER,
  SHOP_GROUP_NA_AFTERMARKET_SHOP: Constants.SHOP_GROUP_NA_AFTERMARKET_SHOP,
  SHOP_GROUP_NA_DIST_BRANCH: Constants.SHOP_GROUP_NA_DIST_BRANCH,
  SHOP_GROUP_NA_SERVICE_SHOP: Constants.SHOP_GROUP_NA_SERVICE_SHOP,
  SHOP_GROUP_NA_GST: Constants.SHOP_GROUP_NA_GST,
  SHOP_GROUP_NA_OTHER: Constants.SHOP_GROUP_NA_OTHER,
  // 欧州一般の店舗区分
  SHOP_GROUP_EU_DIST_HQ: Constants.SHOP_GROUP_EU_DIST_HQ,
  SHOP_GROUP_EU_DIST_BRANCH: Constants.SHOP_GROUP_EU_DIST_BRANCH,
  SHOP_GROUP_EU_DIST_INDEPENDENT: Constants.SHOP_GROUP_EU_DIST_INDEPENDENT,
  SHOP_GROUP_EU_DEALER: Constants.SHOP_GROUP_EU_DEALER,
  SHOP_GROUP_EU_SERVICE_SHOP: Constants.SHOP_GROUP_EU_SERVICE_SHOP,
  SHOP_GROUP_EU_OTHER: Constants.SHOP_GROUP_EU_OTHER,
  // その他の店舗区分
  SHOP_GROUP_OTHER_SERVICE_DEPERTMENT: Constants.SHOP_GROUP_OTHER_SERVICE_DEPERTMENT,
  SHOP_GROUP_OTHER_ENGINEERING_DEPERTMENT: Constants.SHOP_GROUP_OTHER_ENGINEERING_DEPERTMENT,
  SHOP_GROUP_OTHER_OTHER: Constants.SHOP_GROUP_OTHER_OTHER,

  // 型式と年改、VINとPubNoを分けるデリミタ
  VEHICLEINFO_DELIMITER: Constants.VEHICLEINFO_DELIMITER,
  // 車両番号を分けるデリミタ
  VEHICLEINFO_VEHICLENUMBER_DELIMITER: Constants.VEHICLEINFO_VEHICLENUMBER_DELIMITER,

  SHOP_NAME_MAX_LEN: Constants.SHOP_NAME_MAX_LEN,
  SHOP_MANAGEMENT_CODE_MAX_LEN: Constants.SHOP_MANAGEMENT_CODE_MAX_LEN,
  SHOP_ADDRESS_MAX_LEN: Constants.SHOP_ADDRESS_MAX_LEN,
  SHOP_REMARKS_MAX_LEN: Constants.SHOP_REMARKS_MAX_LEN,
  COUNTRY_SSMCODE_MAX_LEN: Constants.COUNTRY_SSMCODE_MAX_LEN,
  COUNTRY_NAMEJA_MAX_LEN: Constants.COUNTRY_NAMEJA_MAX_LEN,
  COUNTRY_NAMEEN_MAX_LEN: Constants.COUNTRY_NAMEEN_MAX_LEN,
  COUNTRY_ABBREVIATION_MAX_LEN: Constants.COUNTRY_ABBREVIATION_MAX_LEN,
  TARGETUNIT_SYSTEMNAME_MAX_LEN: Constants.TARGETUNIT_SYSTEMNAME_MAX_LEN,
  TARGETUNIT_NAME_MAX_LEN: Constants.TARGETUNIT_NAME_MAX_LEN,
  ENGINE_NAME_MAX_LEN: Constants.ENGINE_NAME_MAX_LEN,
  INTAKE_NAME_MAX_LEN: Constants.INTAKE_NAME_MAX_LEN,
  TM_NAME_MAX_LEN: Constants.TM_NAME_MAX_LEN,
  REPRO_NAME_MAX_LEN: Constants.REPRO_NAME_MAX_LEN,
  VEHICLEINFO_CODE_MAX_LEN: Constants.VEHICLEINFO_CODE_MAX_LEN,
  VEHICLEINFO_VEHICLE_MODEL_MAX_LEN: Constants.VEHICLEINFO_VEHICLE_MODEL_MAX_LEN,
  VEHICLEINFO_MODEL_MAX_LEN: Constants.VEHICLEINFO_MODEL_MAX_LEN,
  VEHICLEINFO_ANNUAL_IMPROVEMENTS_MAX_LEN: Constants.VEHICLEINFO_ANNUAL_IMPROVEMENTS_MAX_LEN,
  VEHICLEINFO_MODEL_YEAR_MAX_LEN: Constants.VEHICLEINFO_MODEL_YEAR_MAX_LEN,
  VEHICLEINFO_CHASSIS_NUMBER1_MAX_LEN: Constants.VEHICLEINFO_CHASSIS_NUMBER1_MAX_LEN,
  VEHICLEINFO_CHASSIS_NUMBER2_MAX_LEN: Constants.VEHICLEINFO_CHASSIS_NUMBER2_MAX_LEN,
  VEHICLEINFO_APPLIED_MODEL_MAX_LEN: Constants.VEHICLEINFO_APPLIED_MODEL_MAX_LEN,
  VEHICLEINFO_VIN_MAX_LEN: Constants.VEHICLEINFO_VIN_MAX_LEN,
  VEHICLEINFO_PUB_NO_MAX_LEN: Constants.VEHICLEINFO_PUB_NO_MAX_LEN,
  VEHICLEINFO_ANNUAL_CLASSIFICATION_MAX_LEN: Constants.VEHICLEINFO_ANNUAL_CLASSIFICATION_MAX_LEN,
  CANIDINFO_CODE_MAX_LEN: Constants.CANIDINFO_CODE_MAX_LEN,
  CANIDINFO_NAME_MAX_LEN: Constants.CANIDINFO_NAME_MAX_LEN,
  CANIDINFO_CANID_MAX_LEN: Constants.CANIDINFO_CANID_MAX_LEN,
  CANIDINFO_FUNC_MAX_LEN: Constants.CANIDINFO_FUNC_MAX_LEN,

  // 車種情報のtypeフィールドの値
  VEHICLEINFO_TYPE_UN_KNOWN: 'unknown',
  VEHICLEINFO_TYPE_DEVELOPMENTCODE: Constants.VEHICLEINFO_TYPE_DEVELOPMENTCODE,
  VEHICLEINFO_TYPE_IMPROVEMENTSCODE: Constants.VEHICLEINFO_TYPE_IMPROVEMENTS_CODE,
  VEHICLEINFO_TYPE_AREA: Constants.VEHICLEINFO_TYPE_AREA,
  VEHICLEINFO_TYPE_VEHICLEMODEL: Constants.VEHICLEINFO_TYPE_VEHICLEMODEL,
  VEHICLEINFO_TYPE_CLASSIFICATION: Constants.VEHICLEINFO_TYPE_CLASSIFICATION,
  VEHICLEINFO_TYPE_MODELYEAR: Constants.VEHICLEINFO_TYPE_MODELYEAR,
  VEHICLEINFO_TYPE_CHASSISNUMBER: Constants.VEHICLEINFO_TYPE_CHASSISNUMBER,
  VEHICLEINFO_TYPE_VIN: Constants.VEHICLEINFO_TYPE_VIN,

  // PFC一覧
  PFC_STATUS_TEMP_REGISTERED: Constants.PFC_PUBLISH_STATUS_UNPUBLISHED,
  PFC_STATUS_RELEASED: Constants.PFC_PUBLISH_STATUS_PUBLISHED,
  PFC_STATUS_NOT_RELEASED: Constants.PFC_PUBLISH_STATUS_DISCONTINUED,
  PFC_STATUS_APPROVALED: Constants.PFC_APPROVAL_STATUS_APPROVED,
  PFC_STATUS_NOT_APPROVALED: Constants.PFC_APPROVAL_STATUS_UNAPPROVED,
  PFC_STATUS_DISTRIBUTOR_ONLY: Constants.PFC_APPROVAL_STATUS_DISTRIBUTOR_ONLY,

  // 車両通信フェーズ(Frontend)
  COMMUNICATION_UI_VIN_REQUIRED: "vin_required",
  COMMUNICATION_UI_VIN_COMPLETED: "vin_completed",
  COMMUNICATION_UI_VEHICLE_CGW: "communication_cgw",
  COMMUNICATION_UI_VEHICLE_OBD: "communication_obd",
  COMMUNICATION_UI_VEHICLE_SYSTEMS: "communication_systems",
  COMMUNICATION_UI_RXSWIN_UPDATED: "rxswin_updated",
  COMMUNICATION_UI_GET_DIAG_CODE: "get_diagcode",
  COMMUNICATION_UI_COMMUNICATION_COMPLETED: "communication_completed",
  COMMUNICATION_UI_CGW_CECU_NOT_COMMUNICATED: "cgw_cecu_not_communicated",
  COMMUNICATION_UI_CONFIRM_READ_LEGISLATED_SUPPORT_IDS: "confirm_read_legislated_support_ids",

  // 車両通信フェーズ(Backend)
  COMMUNICATION_API_READ_VBAT: Constants.VEHICLE_INFO_CHECK_STATUS_READ_BATTERY,
  COMMUNICATION_API_CHECK_CGW_SUPPORT: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_CGW,
  COMMUNICATION_API_READ_INSTALLED_ECU: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_INSTALLED_ECU,
  COMMUNICATION_API_READ_COMMUNICATED_ECU: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_COMMUNICATED_ECU,
  COMMUNICATION_API_READ_VIN: Constants.VEHICLE_INFO_CHECK_STATUS_READ_VIN,
  COMMUNICATION_API_READ_SECURITY_ACCESS: Constants.VEHICLE_INFO_CHECK_STATUS_READ_SECURITY_ACCESS,
  COMMUNICATION_API_ALL_TARGET_INQUIRY: Constants.VEHICLE_INFO_CHECK_STATUS_ALL_TARGET_INQUIRY,
  COMMUNICATION_API_COMPLETED: Constants.VEHICLE_INFO_CHECK_STATUS_COMPLETED,
  COMMUNICATION_API_READ_RxSWIN: Constants.VEHICLE_INFO_CHECK_STATUS_READ_RxSWIN,
  COMMUNICATION_API_UPDATING_RxSWIN: Constants.VEHICLE_INFO_CHECK_STATUS_RXSWIN_UPDATING,
  COMMUNICATION_API_CHASSIS_COMPLETED: Constants.VEHICLE_INFO_VEHICLE_CHECK_STATUS_CHASSIS_COMPLETED,
  COMMUNICATION_API_VIN_COMPLETED: Constants.VEHICLE_INFO_VEHICLE_CHECK_STATUS_VIN_COMPLETED,
  COMMUNICATION_API_VIN_FAILED: Constants.VEHICLE_INFO_VEHICLE_CHECK_STATUS_FAILED,
  COMMUNICATION_API_DIAG_INFO: Constants.VEHICLE_INFO_CHECK_STATUS_DIAG_INFO,
  COMMUNICATION_API_READ_VEHICLE_TIMESTAMP: Constants.VEHICLE_INFO_CHECK_STATUS_READ_TIMESTAMP,
  COMMUNICATION_API_INSTALL_ECU: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_INSTALLED_COMMUNICATED_ECU,
  COMMUNICATION_API_READ_LEGISLATED_SUPPORT_IDS: Constants.VEHICLE_INFO_CHECK_STATUS_REA_REGISLATED_SUPPORT_IDS,
  COMMUNICATION_API_READ_LEGISLATED_DATA: Constants.VEHICLE_INFO_CHECK_STATUS_READ_LEGISLATED_DATA,
  COMMUNICATION_API_CGW_CECU_NOT_COMMUNICATED: Constants.VEHICLE_INFO_CHECK_STATUS_CGW_CECU_NOT_COMMUNICATED,
  COMMUNICATION_API_CONFIRM_READ_LEGISLATED_SUPPORT_IDS: Constants.VEHICLE_INFO_CHECK_STATUS_CONFIRM_READ_LEGISLATED_SUPPORT_IDS,

  // 車両情報
  SYSTEM_STATUS_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_REPROGRAMMABLE,
  SYSTEM_STATUS_NOT_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_NOT_REPROGRAMMABLE,
  SYSTEM_STATUS_INSTALLED: Constants.INSTALL_STATUS_INSTALLED,
  SYSTEM_STATUS_NOT_INSTALLED: Constants.INSTALL_STATUS_NOT_INSTALLED,
  SYSTEM_STATUS_COMMUNICATED: Constants.COMMUNICATE_STATUS_COMMUNICATED,
  SYSTEM_STATUS_NOT_COMMUNICATED: Constants.COMMUNICATE_STATUS_NOT_COMMUNICATED,

  // ID Type
  TARGET_ID_TYPE_ROMID: Constants.TARGET_ID_TYPE_ROMID,
  TARGET_ID_TYPE_CID: Constants.TARGET_ID_TYPE_CID,
  TARGET_ID_TYPE_SWID: Constants.TARGET_ID_TYPE_SWID,

  // リプロ実行可能判定
  REPRO_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_REPROGRAMMABLE,
  REPRO_NOT_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_NOT_REPROGRAMMABLE,
  REPRO_REPROGRAMMABLE_ERROR: Constants.PFC_REPROGRAM_STATUS_ERROR,
  REPRO_INSTALLED: Constants.INSTALL_STATUS_INSTALLED,
  REPRO_NOT_INSTALLED: Constants.INSTALL_STATUS_NOT_INSTALLED,
  REPRO_INSTALLED_ERROR: Constants.INSTALL_STATUS_ERROR,
  REPRO_COMUNICATED: Constants.COMMUNICATE_STATUS_COMMUNICATED,
  REPRO_NOT_COMUNICATED: Constants.COMMUNICATE_STATUS_NOT_COMMUNICATED,
  REPRO_COMUNICATED_ERROR: Constants.COMMUNICATE_STATUS_ERROR,
  STATUS_RPR_ENABLED: "enabled",
  STATUS_RPR_DISABLED: "disabled",
  STATUS_UPDATE_CONSENT: Constants.REPRO_CONSENT_STATUS_CONSENTED,
  STATUS_UPDATE_REJECT: Constants.REPRO_CONSENT_STATUS_REJECTED,
  STATUS_CONSENT_UNSETTED: Constants.REPRO_CONSENT_STATUS_UNSETTED,

  // Repro programming states
  REPRO_FILE_DOWNLOADING: Constants.REPRO_FILE_DOWNLOADING,
  REPRO_PRE_PROGRAMMING: Constants.REPRO_PRE_PROGRAMMING,
  REPRO_PROGRAMMING: Constants.REPRO_PROGRAMMING,
  REPRO_POST_PROGRAMMING: Constants.REPRO_POST_PROGRAMMING,

  // ポーリング状況
  POLLING_INITIALIZED: "init",
  POLLING_RUNNING: "run",
  POLLING_COMPLETED: "complete",
  POLLING_FAILURE: "failure",

  // EXECUTE 画面での処理種別
  PROCESS_IDLING: "idling",
  PROCESS_REPRO_PROCESSING: "processing",
  PROCESS_REPRO_COMPLETED: "completed",
  PROCESS_REPRO_SUB_FAILED: "usb_failed",
  // EXECUTE 画面でのコンテナ種別
  CONTAINER_VEHICLE: "vehicle",
  CONTAINER_ATTENTION: "attention",
  CONTAINER_EXECUTING: "executing",
  CONTAINER_CHECK_IG_OFF: "ig-off",
  CONTAINER_CHECK_IG_ON: "ig-on",
  CONTAINER_IG_COUNT_DOWN: "ig-count-down",
  CONTAINER_CCU_REPROGRAMMING: "ccu-reprogramming",
  CONTAINER_CONFIRM_COMPLETE_CCU_REPROGRAMMING: "confirm-complete-ccu-reprogramming",

  // リプロ実行結果
  STATUS_RPR_EXECUTING_ERROR: "executing-error",
  STATUS_RPR_FINISHED_ERROR: "finished-error",
  STATUS_RPR_FINISHED_SUCCESS: "finished-success",

  // ソートのソート順定義
  ASC: "asc",
  DESC: "desc",

  // List item specification
  LIST_ITEM_HIGHLIGHT: "highlight",
  LIST_ITEM_ERROR: "error",
  LIST_ITEM_BOLD: 'bold',
  LIST_ITEM_DISABLE: 'disable',

  // 会社ツリー更新用の定義
  TREE_UPDATE_TPYE_NONE: "none",
  TREE_UPDATE_TPYE_ALL: "all",
  TREE_UPDATE_TPYE_ID: "id",
  TREE_UPDATE_TPYE_UPDATE: "update",
  TREE_UPDATE_TPYE_DELETE: "delete",

  // 共通のハイフン
  COMMON_HYPHEN_VALUE: '-',
  // バージョン未取得時の定義
  VERSION_UNKNOWN: Constants.VERSION_UNKNOWN,
  // バージョン未取得時の表示
  VERSION_UNKNOWN_DISPLAY: "-.-.-",

  PFC_FILE_CONFIGURATION: [
    'engine_id', 'aspiration_id', 'transmission_id', 'repro_factor_id', 'is_connected',
    'short_connector', 'cvn', 'service_news', 'supplementary_en', 'supplementary_ja',
    'remarks', 'details_en', 'details_ja', 'notes_en', 'notes_ja'
  ],

  PFC_HISTORY_CONFIGURATION: [],

  SERVICE_NEWS_MAX_COUNT: 25,
  WAIT_DB_EVENT_TIME: 1500,

  // PFC file and unit list types
  PFC_FILE_LIST_TYPE_OWN_COUNTRY: Constants.PFC_FILE_LIST_TYPE_OWN_COUNTRY,
  PFC_FILE_LIST_TYPE_OTHER_COUNTRY: Constants.PFC_FILE_LIST_TYPE_OTHER_COUNTRY,

  // G-PLM 連携
  GPLM_DL_REPRO_FILE: Constants.DOWNLOAD_REPROGRAMMING_FILES,
  GPLM_DL_CONFIG_INFO: Constants.DOWNLOAD_CONFIGURATION_INFORMATION,

  // PFC file type
  PFC_FILE_TYPE_DEFAULT: 'pfc',
  PFC_FILE_TYPE_CCU: 'ccu',

  // PFC file 登録方法
  PFC_FILE_REGISTERD_BY_GPLM: 'gplm',
  PFC_FILE_REGISTERD_BY_MANUAL: 'manual',

  DTC_STATUS_TENTATIVE: Constants.DTC_STATUS_TENTATIVE,
  DTC_STATUS_CURRENT: Constants.DTC_STATUS_CURRENT,
  DTC_STATUS_PAST: Constants.DTC_STATUS_PAST,
  DTC_STATUS_CONFIRM: Constants.DTC_STATUS_CONFIRM,
  DTC_STATUS_LATEST: Constants.DTC_STATUS_LATEST,
  DTC_STATUS_CONFIRM_LATEST: Constants.DTC_STATUS_CONFIRM_LATEST,
  DTC_STATUS_CONFIRM_TENTATIVE: Constants.DTC_STATUS_CONFIRM_TENTATIVE,
  DTC_STATUS_TENTATIVE_LATEST: Constants.DTC_STATUS_TENTATIVE_LATEST,
  DTC_STATUS_CONFIRM_TENTATIVE_LATEST: Constants.DTC_STATUS_CONFIRM_TENTATIVE_LATEST,
  DTC_STATUS_NO_DTC: Constants.DTC_STATUS_NO_DTC,
  DTC_STATUS_DTC_NOT_SUPPORT: Constants.DTC_STATUS_NOT_SUPPORT,
  DTC_STATUS_CANNNOT_CONNECT: Constants.DTC_STATUS_CANNOT_CONNECT,
  DTC_STATUS_CURRENT_PAST: Constants.DTC_STATUS_CURRENT_PAST,

  BMC_STATUS_CANNOT_CONNECT: 'bmc_status_cannot_connect',
  BMC_STATUS_NO_BMC: 'bmc_status_no_bmc',

  // 実行回数
  USAGE_COUNT_REPRO: Constants.USAGE_COUNT_TYPE_REPRO,
  USAGE_COUNT_DIAG: Constants.USAGE_COUNT_TYPE_DIAG,
  USAGE_COUNT_DTC: Constants.USAGE_COUNT_TYPE_DTC,
  USAGE_COUNT_VCH: Constants.USAGE_COUNT_TYPE_VCH,
  USAGE_COUNT_CST: Constants.USAGE_COUNT_TYPE_CST,
  USAGE_COUNT_WSP: Constants.USAGE_COUNT_TYPE_WSP,
  USAGE_COUNT_CNC: Constants.USAGE_COUNT_TYPE_CNC,
  USAGE_COUNT_SP_DTC: Constants.USAGE_COUNT_TYPE_SP_DTC,
  USAGE_COUNT_SP_CNC: Constants.USAGE_COUNT_TYPE_SP_CNC,
  USAGE_COUNT_SP_WSP: Constants.USAGE_COUNT_TYPE_SP_WSP,
  USAGE_COUNT_DATA_PLAYBACK: Constants.USAGE_COUNT_TYPE_DATA_PLAYBACK,
  USAGE_COUNT_IMMOBILIZER: Constants.USAGE_COUNT_TYPE_IMMOBILIZER,
  USAGE_COUNT_MACKEY: Constants.USAGE_COUNT_TYPE_MACKEY,
  USAGE_COUNT_TYPE_REAL_WORLD_DATA: Constants.USAGE_COUNT_TYPE_REAL_WORLD_DATA,
  USAGE_COUNT_DM: Constants.USAGE_COUNT_TYPE_DM,
  USAGE_COUNT_AT: Constants.USAGE_COUNT_TYPE_AT,
  USAGE_COUNT_SP_DM: Constants.USAGE_COUNT_TYPE_SP_DM,

  // 車両情報取得種別
  // ALL : リプロ・診断
  INQUIRY_TYPE_ALL: Constants.VEHICLE_INFO_INQUIRY_TYPE_ALL,
  // DTC : 診断 のみ
  INQUIRY_TYPE_DTC: Constants.VEHICLE_INFO_INQUIRY_TYPE_DTC,

  // Protocol名
  PROTOCOL_FHI_CAN: Constants.PROTOCOL_FHI_CAN,
  PROTOCOL_PHASE4: Constants.PROTOCOL_Phase4,
  PROTOCOL_PHASE5: Constants.PROTOCOL_Phase5,

  // 機能
  EXECUE_FUNCTION_TYPE_RPR: Constants.FUNCTION_TYPE_REPRO,
  EXECUE_FUNCTION_TYPE_DIAG: Constants.FUNCTION_TYPE_DIAGNOSIS,
  EXECUE_FUNCTION_TYPE_MACKKEY: Constants.FUNCTION_TYPE_MAC_KEY,
  EXECUE_FUNCTION_TYPE_IMMOBILIZER: Constants.FUNCTION_TYPE_IMMOBILIZER,
  EXECUE_FUNCTION_TYPE_DATAPLAYBACK: Constants.FUNCTION_TYPE_DATA_PLAYBACK,
  EXECUE_FUNCTION_TYPE_INDIVIDUAL_DIAG: Constants.FUNCTION_TYPE_INDIVIDUAL_DIAGNOSIS,
  EXECUE_FUNCTION_TYPE_SPECIAL_FEATURE_ECU_TABLE: Constants.FUNCTION_TYPE_SPECIAL_FEATURE,
  EXECUE_FUNCTION_TYPE_INDIVIDUAL_ECU_TABLE: Constants.FUNCTION_TYPE_INDIVIDUAL,
  EXECUE_FUNCTION_TYPE_REAL_WORLD_DATA: Constants.FUNCTION_TYPE_REAL_WORLD_DATA,
  // 管理画面/機能プランで使用する機能一覧（格納順に表示される）
  FUNCTION_LIST: [
    Constants.FUNCTION_TYPE_REPRO,
    Constants.FUNCTION_TYPE_DATA_PLAYBACK,
    Constants.FUNCTION_TYPE_DIAGNOSIS,
    Constants.FUNCTION_TYPE_IMMOBILIZER,
    Constants.FUNCTION_TYPE_MAC_KEY,
    Constants.FUNCTION_TYPE_INDIVIDUAL_DIAGNOSIS,
    Constants.FUNCTION_TYPE_REAL_WORLD_DATA,
  ],

  // 個別診断機能
  INDIVIDUAL_SELECT: 'individualSelect',
  INDIVIDUAL_FUNCTION_DIAG_CODE: 'individualDiagCode',
  INDIVIDUAL_FUNCTION_CANCEL_CODE: 'individualCancelCode',
  INDIVIDUAL_FUNCTION_DATA_MONITOR: 'individualDataMonitor',
  INDIVIDUAL_FUNCTION_VEHICLE_CONTROL_HISTORY: 'individualVehicleControlHistory',
  INDIVIDUAL_FUNCTION_ACTIVE_TEST: 'individualActiveTest',
  INDIVIDUAL_FUNCTION_WORK_SUPPORT: 'individualWorkSupport',
  INDIVIDUAL_FUNCTION_CUSTOMIZE: 'individualCustomize',

  // 特殊機能
  SPECIAL_FEATURE_SELECT: 'specialFeatureSelect',
  SPECIAL_FEATURE_DIAG_CODE: 'spDiagCode',
  SPECIAL_FEATURE_CANCEL_CODE: 'spCancelCode',
  SPECIAL_FEATURE_DATA_MONITOR: 'spDataMonitor',
  SPECIAL_FEATURE_WORK_SUPPORT: 'spWorkSupport',

  // 個車情報取得
  REAL_WORLD_DATA_SELECT: 'realWorldDataTable',

  // メモ情報
  MEMOINFO_MAX_LEN: Constants.MEMOINFO_MAX_LEN,
  MEMOINFO_DETA_NAME_MAX_LEN: Constants.MEMOINFO_DETA_NAME_MAX_LEN,

  // FFDプルダウンType
  FFD_TYPE_TENTATIVE: Constants.FFD_DATA_TYPE_TENTATIVE,
  FFD_TYPE_CONFIRM: Constants.FFD_DATA_TYPE_CONFIRM,

  // 個別診断の API 呼び出し共通処理の POST 失敗エラー
  INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE: 'post_invalid_response',
  // 個別診断の API 呼び出し共通処理の GET 失敗エラー
  INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE: 'get_invalid_response',
  // 個別診断の API 呼び出し共通処理のポーリング回数上限エラー
  INDIVIDUAL_API_CALL_POLLING_COUNT_MAX: 'polling_count_max',

  // 個別診断の ポーリング共通処理においてポーリング続行する場合に使用
  INDIVIDUAL_POLLING_RESULT_CONTINUE: 'continue',
  // 個別診断の ポーリング共通処理においてポーリング終了する場合に使用
  INDIVIDUAL_POLLING_RESULT_FINISH: 'finish',
  // 個別診断の ポーリング共通処理においてエラー終了する場合に使用
  INDIVIDUAL_POLLING_RESULT_ERROR: 'error',

  // 作業サポートAPIのレスポンスで返却されるStatus
  STATUS_WSP_COMPLETED: Constants.WORK_SUPPORT_REQUEST_STATUS_COMPLETED,
  STATUS_WSP_FAILED: Constants.WORK_SUPPORT_REQUEST_STATUS_FAILED,
  STATUS_WSP_IN_PROGRESS: Constants.WORK_SUPPORT_REQUEST_STATUS_IN_PROGRESS,

  // データモニタ・アクティブテストAPIのレスポンスで返却されるStatus
  STATUS_DMAT_COMPLETED: Constants.DATA_MONITOR_ACTIVE_TEST_STATUS_COMPLETED,
  STATUS_DMAT_FAILED: Constants.DATA_MONITOR_ACTIVE_TEST_STATUS_FAILED,
  STATUS_DMAT_IN_PROGRESS: Constants.DATA_MONITOR_ACTIVE_TEST_STATUS_IN_PROGRESS,

  // 制御作動履歴APIのレスポンスで返却されるStatus
  STATUS_VCH_COMPLETED: Constants.VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_COMPLETED,
  STATUS_VCH_FAILED: Constants.VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_FAILED,
  STATUS_VCH_IN_PROGRESS: Constants.VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_IN_PROGRESS,

  // リプロAPIのレスポンスで返却されるStatus
  STATUS_REPRO_COMPLETED: Constants.REPRO_REQUEST_STATUS_COMPLETED,
  STATUS_REPRO_FAILED: Constants.REPRO_REQUEST_STATUS_FAILED,
  STATUS_REPRO_IN_PROGRESS: Constants.REPRO_REQUEST_STATUS_IN_PROGRESS,

  // 制御作動履歴APIのレスポンスで返却されるres_type
  VCH_RESPONSE_TYPE_OK: Constants.RESPONSE_TYPE_OK,
  VCH_RESPONSE_TYPE_NG: Constants.RESPONSE_TYPE_NG,
  VCH_RESPONSE_TYPE_NO: Constants.RESPONSE_TYPE_no,

  // カスタマイズAPIのレスポンスで返却されるStatus
  STATUS_CSTMS_COMPLETED: Constants.VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_COMPLETED,
  STATUS_CSTMS_FAILED: Constants.VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_FAILED,
  STATUS_CSTMS_IN_PROGRESS: Constants.VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_IN_PROGRESS,

  // カスタマイズ結果のAPIレスポンスで返却されるstatus
  CSTM_STATUS_TYPE_SUCCESS: 'success',
  CSTM_STATUS_TYPE_FAILURE: 'failure',
  CSTM_STATUS_TYPE_ERROR: 'error',
  CSTM_STATUS_TYPE_NOT_AVAILABLE: 'not_available',

  // キャンセルコードAPIのレスポンスで返却されるStatus
  STATUS_CNC_COMPLETED: Constants.CANCEL_CODE_REQUEST_STATUS_COMPLETED,
  STATUS_CNC_FAILED: Constants.CANCEL_CODE_REQUEST_STATUS_FAILED,
  STATUS_CNC_IN_PROGRESS: Constants.CANCEL_CODE_REQUEST_STATUS_IN_PROGRESS,

  // キャンセルコードAPIのレスポンスで返却されるres_type
  CNC_RESPONSE_TYPE_OK: Constants.RESPONSE_TYPE_OK,
  CNC_RESPONSE_TYPE_NG: Constants.RESPONSE_TYPE_NG,
  CNC_RESPONSE_TYPE_NO: Constants.RESPONSE_TYPE_no,

  // 機能プラン
  DEFAULT_PLAN_ID: Constants.DEFAULT_PLAN_ID,
  DEFAULT_PLAN_ID_SOA_USER: Constants.DEFAULT_PLAN_ID_SOA_USER,
  PLAN_NAME_MAX_LEN: Constants.PLAN_NAME_MAX_LEN,

  // イベントログ生成APIのレスポンスで返却されるstatus
  EVENT_LOG_TYPE_SUCCESS: 'success',
  EVENT_LOG_TYPE_FAILED: 'failed',
  // PFC repro type
  REPRO_TYPE_FORCE: Constants.REPRO_TYPE.FORCE,
  REPRO_TYPE_OPTIONAL: Constants.REPRO_TYPE.OPTIONAL,
  REPRO_TYPE_MERCHANTABILITY: Constants.REPRO_TYPE.MERCHANTABILITY,
  REPRO_TYPE_NOT_SET: Constants.REPRO_TYPE.NOT_SET,
  REPRO_TYPE_RESTORE: Constants.REPRO_TYPE.RESTORE,
  // 仕向け地判定情報 文字数
  REGIONINFORMATION_NAME_LEN: 2,

  // 会社グループ情報
  COUNTRY_GROUP_EUROPE: Constants.COUNTRY_GROUP_EUROPE,

  // conitoのUIから起動の場合の動作確認用情報
  STATE_EXTERNAL_AUTH_TEST: 'externalAuthTest',

  MAINTE_TYPE_VEHICLE_INFO: 'VEHICLEINFO',
  MAINTE_TYPE_REPRO_INFO: 'REPROINFO',

  // 車種情報-年改区分情報
  VEHICLEINFO_NOT_SET_IMPROVEMENTS_CODE: Constants.VEHICLEINFO_NOT_SET_IMPROVEMENTS_CODE,

  // ポップメニューの画面名（前画面遷移用）
  POPUP_MENU_DISPLAY_NAME: ['changeUnit', 'changePassword'],
  // マニュアルのタイプ
  MANUAL_TECH: 'technician',
  MANUAL_ADMIN: 'administrator',
});