<div class="data-monitor-virtual-list-dev" ref="virtual_list_dev">
  <b-button v-if="isDebug" @click="downloadTSV" style="width: 70px !important; height: 20px !important; font-size: 9px !important;">Download</b-button><!-- for debug -->
  <div class="virtual-list-title">
    <div v-for="column in columns" 
      :key="column.name" 
      :class="['title-row', getTitleStyle(column.name)]">
      {{ column.label }}
    </div>
    <div v-show="isListScrolled" class="title-dummy">&nbsp;</div><!-- dummy -->
  </div>
  <virtual-list class="virtual-list"
    :data-key="'name'" 
    :data-sources="items" 
    :data-component="itemComponent"
    :keeps=50
  />
</div>
