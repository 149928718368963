<div class="container-fluid datamonitor-select">
  <div class="row contents-div">
    <!-- left list -->
    <div class="left-div">
      <b-form class="filter-form mt-4" autocomplete="off">
        <b-form-input v-model="filterText" :placeholder="$t('individual.label_data_monitor_filter')" type="text"
          id="filter-text" class="" size="sm" autocomplete="off" @input="updateFilter" />
      </b-form>
      <cstm-table
        useStripeStyle
        rowSelect
        usePopup
        useSort
        hasTabs
        class="support-table mt-2"
        type="simple"
        ref="filtered_tbl"
        :currentSortkey="currentSortkey"
        :items="filterFunctionList"
        :columns="systemTableColumns"
        @rowClicked="clickRow"
      >
      </cstm-table>
      <b-form-group>
        <b-form-textarea
          type="text"
          :value="selectedFunction.menu_description"
          :placeholder="$t('individual.common_label_description')"
          class="mt-3"
          style="background-color: white"
          readonly
          no-resize
          tabindex="-1"
          size="sm"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>
    </div>

    <!-- middle btns -->
    <div class="middle-div px-0">
      <div class="button-container text-center short-buttons">
        <b-button
          @click="handleAllAppendButtonClick"
          size="sm"
          class="btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_all_add') }}</b-button
        ><br />
        <b-button
          @click="handleAppendButtonClick"
          size="sm"
          class="mt-2 btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_add') }}</b-button
        ><br />
        <b-button
          @click="handleRemoveButtonClick"
          size="sm"
          class="mt-2 btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_delete') }}</b-button
        ><br />
        <b-button
          @click="handleAllRemoveButtonClick"
          size="sm"
          class="mt-2 btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_all_delete') }}</b-button
        >
      </div>
    </div>
    <!-- right list -->
    <div class="right-div">
      <cstm-table
        useStripeStyle
        rowSelect
        hasTabs
        usePopup
        initSortDir="asc"
        id="selected"
        class="support-table mt-3 mb-2"
        type="simple"
        :items="selectedFunctionList"
        :columns="cstmTableColumns"
        @rowClicked="clickRowRemove"
      >
      </cstm-table>
      <div class="text-right w-100">
        <b-button
          @click="startExecute"
          size="sm"
          class="mt-2"
          variant="primary"
        >
          {{ $t('individual.button_data_monitor_start') }}
        </b-button>
      </div>
    </div>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>
