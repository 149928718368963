/* eslint-disable complexity */
/* eslint-disable max-statements */
/**
 * DID data conversion from data to display value
 * @param conversionList
 * @param systemId
 */
// eslint-disable-next-line complexity
function convertDataToDisplayValue(dataByteArray = [], conversionInfo = {}) {
    if (!dataByteArray || dataByteArray.length === 0) {
        return "";
    }
    let displayValue = "";
    if (!conversionInfo || !conversionInfo || !conversionInfo.data_type)
        return displayValue;

    switch (conversionInfo.data_type) {
        case "FLG":
            displayValue = convertFlgData(dataByteArray[0], conversionInfo);
            break;
        case "HEX":
            displayValue = convertHexData(dataByteArray);
            break;
        case "Value":
            displayValue = convertValueData(dataByteArray, conversionInfo);
            break;
        case "ASCII":
            displayValue = new TextDecoder("latin1").decode(new Uint8Array(dataByteArray));
            break;
        case "Bin":
            for (let index = 0; index < dataByteArray.length; index++) {
                displayValue += dataByteArray[index].toString(2).padStart(8, "0");
            }
            break;
        case "YYMMDD":
            if (dataByteArray.length >= 3) {
                const year = dataByteArray[0].toString().padStart(2, "0");
                const month = dataByteArray[1].toString().padStart(2, "0");
                const date = dataByteArray[2].toString().padStart(2, "0");
                displayValue = `${year}.${month}.${date}`;
            }
            break;
        case "YYYYMMDD":
            if (dataByteArray.length >= 4) {
                const year = (dataByteArray[0] << 8) | dataByteArray[1];
                const month = dataByteArray[2];
                const date = dataByteArray[3];
                if (year < 0 || month < 0 || month >= 13 || date < 0 || date >= 32) {
                    displayValue = "-";
                } else {
                    displayValue = `${year.toString().padStart(4, "0")}/${month.toString().padStart(2, "0")}/${date.toString().padStart(2, "0")}`;
                }
            }
            break;
        case "hhmmss":
            displayValue = convertTimeData(dataByteArray);
            break;
        case "YYYYMMDDhhmm":
            displayValue = convertDateTimeUptoMinuteData(dataByteArray);
            break;
        case "PRG_YYYYMMDDhhmm":
            displayValue = convertPrgDateData(dataByteArray, false);
            break;
        case "YYYYMMDDhhmmss":
            displayValue = convertPrgDateData(dataByteArray, true);
            break;
        case "min2hhmm":
            {
                const dataView = new DataView(new Uint8Array(dataByteArray.slice(0, 2)).buffer);
                const totalSeconds = dataView.getUint16(0);
                const hours = Math.floor(totalSeconds / 60).toString().padStart(2, "0");
                const minutes = (totalSeconds % 60).toString().padStart(2, "0");
                displayValue = `${hours} hrs, ${minutes} min`;
            }
            break;
        case "sec2hhmm":
            {
                const dataView = new DataView(new Uint8Array(dataByteArray.slice(0, 4)).buffer);
                const totalSeconds = dataView.getUint32(0);
                const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, "0");
                const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, "0");
                displayValue = `${hours} hrs, ${minutes} min`;
            }
            break;
        case "sec2hhmmss":
            {
                const dataView = new DataView(new Uint8Array(dataByteArray.slice(0, 4)).buffer);
                const totalSeconds = dataView.getUint32(0);
                const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, "0");
                const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, "0");
                const seconds = ((totalSeconds % 3600) % 60).toString().padStart(2, "0");
                displayValue = `${hours}:${minutes}:${seconds}`;
            }
            break;
        case "Lat_DEG":
            {
                const latDeg = convertValueData(dataByteArray, conversionInfo);
                displayValue = `${latDeg} ${latDeg >= 0 ? "N" : "S"}`;
            }
            break;
        case "Lng_DEG":
            {
                const lngDeg = convertValueData(dataByteArray, conversionInfo);
                displayValue = `${lngDeg} ${lngDeg >= 0 ? "E" : "W"}`;
            }
            break;
        case "Lat_DMM":
            displayValue = convertLatDmmData(dataByteArray);
            break;
        case "Lng_DMM":
            displayValue = convertLngDmmData(dataByteArray);
            break;
        case "LatLng_DMM":
            {
                const latDmm = convertLatDmmData(dataByteArray.slice(0, 4));
                const lngDmm = convertLngDmmData(dataByteArray.slice(4, 8));
                if (latDmm === "-" || lngDmm === "-") {
                    displayValue = "-";
                } else {
                    displayValue = `${latDmm},${lngDmm}`;
                }
            }
            break;
        case "ACL2INC":
            displayValue = convertAcl2IncValue(dataByteArray, conversionInfo);
            break;
        // case "decExStr":
        // 呼び出し元で分岐するため不要
        // break;
        case "decWithDot":
            {
                const decimalsValues = [];
                for (let index = 0; index < dataByteArray.length; index++) {
                    decimalsValues.push(dataByteArray[index].toString(10));
                }
                displayValue = decimalsValues.join(".");
            }
            break;
        case "MFD_BCD1":
            displayValue = convertMfdBcd1Data(dataByteArray);
            break;
        case "MFD_BCD2":
            displayValue = convertMfdBcd2Data(dataByteArray);
            break;
        default:
            break;
    }
    return displayValue;
}

function convertFlgData(dataByte, conversionInfo) {
    const FLG_TRUE = "1";
    const FLG_FALSE = "0";
    let specBitStr = "";
    if (conversionInfo.value_spec_bit) {
        switch (conversionInfo.value_spec_bit) {
            case FLG_TRUE:
                specBitStr = "_supp_FF";
                break;
            case FLG_FALSE:
                specBitStr = "_supp_00";
                break;
            default:
                break;
        }
    }

    if (conversionInfo.byte_or_bit === "byte") {
        return dataByte.toString(16).padStart(2, "0").toUpperCase() === "00" ? "00" + specBitStr : "FF";
    } else if (conversionInfo.byte_or_bit === "bit") {
        return dataByte.toString(10) === "0" ? "00" + specBitStr : "FF";
    }
    return "";
}

function convertHexData(dataBytes) {
    let displayValue = "";
    for (let i = 0; i < dataBytes.length; i++) {
        const dataByte = dataBytes[i].toString(16).padStart(2, "0").toUpperCase();
        displayValue += dataByte;
    }
    return displayValue;
}

function convertHexToInt(dataByteArray, signature) {
    let byteStr = '';
    for (let i = 0; i < dataByteArray.length; i++) {
        byteStr += dataByteArray[i].toString(16).padStart(2, "0");
    }

    let valueTemp = parseInt(`0x${byteStr}`, 16);

    if (signature === "signed") {
        // bit size(1桁=4bit)
        const bitSize = byteStr.length * 4;
        // 符号なし整数の最大値
        const maxUnsignedValue = Math.pow(2, bitSize) - 1;
        // 符号付き整数の最大値
        const maxSignedValue = Math.pow(2, bitSize - 1) - 1;
        // 符号付き整数の最小値
        const minSignedValue = -Math.pow(2, bitSize - 1);

        // 符号付き整数の最大値を超える場合
        if (valueTemp > maxSignedValue) {
            valueTemp = valueTemp - (maxUnsignedValue + 1);
        }
        // 符号付き整数の最小値を下回る場合
        else if (valueTemp < minSignedValue) {
            valueTemp = valueTemp + (maxUnsignedValue + 1);
        }
    }

    return valueTemp;
}

function convertValueData(dataByte, conversionInfo) {
    // Physical conversion
    const intValue = convertHexToInt(dataByte, conversionInfo.signature);
    const physicalValue = (intValue * conversionInfo.scaling_bit) + conversionInfo.offset;

    // Unit conversion
    const unitValue = convertUnitValueData(physicalValue, conversionInfo);

    // Format number using fixed-point notation.
    return unitValue.toFixed(conversionInfo.digit_after_decimal);
}

/** Unit Conversion(SI->US) */
function convertUnitValueData(dataValue, conversionInfo) {
    const unit_conversion = conversionInfo.unit_conversion || null;

    if (unit_conversion &&
        unit_conversion.scaling !== undefined && unit_conversion.offset !== undefined) {
        return ((dataValue * unit_conversion.scaling) + unit_conversion.offset);
    } else {
        return dataValue;
    }
}

function convertPhysicalValueData(dataValue, conversionInfo) {
    const floatValue = parseFloat(dataValue);

    // Unit conversion
    const unitValue = convertUnitValueData(floatValue, conversionInfo);

    // Format number using fixed-point notation.
    return unitValue.toFixed(conversionInfo.digit_after_decimal);
}

function convertTimeData(dataByteArray) {
    if (dataByteArray.length < 3) {
        return "";
    }
    const hours = dataByteArray[0];
    const minutes = dataByteArray[1];
    const seconds = dataByteArray[2];
    if (hours >= 24 || minutes >= 60 || seconds >= 60) {
        // display "" (nothing)
        return "";
    } else {
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
}

function convertDateTimeUptoMinuteData(dataByteArray) {
    if (dataByteArray.length < 4) {
        return "";
    }
    const year = 2000 + (dataByteArray[0] >> 1);
    const month = ((dataByteArray[0] << 3) & 0x8) | (dataByteArray[1] >> 5);
    const date = dataByteArray[1] & 0x1F;
    const hours = dataByteArray[2] >> 3;
    const minutes = ((dataByteArray[2] << 3) & 0x38) | (dataByteArray[3] >> 5);
    if (year === 2127 || month === 0 || date === 0 || hours === 31 || minutes === 63) {
        return "-";
    } else {
        const datePart = `${year.toString().padStart(4, "0")}/${month.toString().padStart(2, "0")}/${date.toString().padStart(2, "0")}`;
        const timePart = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
        return `${datePart} ${timePart}`;
    }
}

function convertPrgDateData(dataByteArray, hasSeconds) {
    const requiredDataLen = hasSeconds ? 6 : 5;
    if (dataByteArray.length < requiredDataLen) {
        return "";
    }

    // Byte to Hex
    let byteStr = '';
    for (let i = 0; i < dataByteArray.length; i++) {
        byteStr += dataByteArray[i].toString(16).toUpperCase().padStart(2, "0");
    }

    // Conversion method: BCD
    let year = 2000 + parseInt(convertMfdBcdValue(byteStr.substring(0, 2), 0), 10);
    let month = parseInt(convertMfdBcdValue(byteStr.substring(2, 4), 0), 10);
    let date = parseInt(convertMfdBcdValue(byteStr.substring(4, 6), 0), 10);
    let hours = parseInt(convertMfdBcdValue(byteStr.substring(6, 8), 0), 10);
    let minutes = parseInt(convertMfdBcdValue(byteStr.substring(8, 10), 0), 10);
    let seconds;
    if (hasSeconds) {
        seconds = parseInt(convertMfdBcdValue(byteStr.substring(10, 12), 0), 10);
    }

    const gmtDateStr = `${year}-${month.toString().padStart(2, "0")}-${date.toString().padStart(2, "0")}T${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${hasSeconds ? seconds.toString().padStart(2, "0") : '00'}`;
    const dateGmt = new Date(gmtDateStr);
    if (dateGmt.toString() !== "Invalid Date") {
        // GMT -> Local Time
        const offset = new Date().getTimezoneOffset() * -1;
        const dateLocal = new Date(dateGmt.valueOf() + (offset * 60 * 1000));
        year = dateLocal.getFullYear();
        month = dateLocal.getMonth() + 1;
        date = dateLocal.getDate();
        hours = dateLocal.getHours();
        minutes = dateLocal.getMinutes();
        if (hasSeconds) {
            seconds = dateLocal.getSeconds();
        }
    }
    const datePart = `${year.toString().padStart(4, "0")}/${month.toString().padStart(2, "0")}/${date.toString().padStart(2, "0")}`;
    let timePart = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    if (hasSeconds) {
        timePart += `:${seconds.toString().padStart(2, "0")}`;
    }
    return `${datePart} ${timePart}`;
}

function convertLatDmmData(dataByteArray) {
    if (dataByteArray.length < 4) {
        return "-";
    }
    const direction = dataByteArray[0] >> 7;
    const degrees = convertValueData([dataByteArray[0] & 0x7F], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const minutes = convertValueData([dataByteArray[1] >> 2], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const seconds = convertValueData([((dataByteArray[1] << 8) & 0x300) | dataByteArray[2]], { signature: "unsigned", scaling_bit: 0.1, offset: 0, digit_after_decimal: 1 });
    if (degrees === 127 || minutes === 63 || seconds === 102.3) {
        return "-";
    } else {
        return `${degrees}° ${minutes}' ${seconds}'' ${direction === 0 ? "N" : "S"}`;
    }
}

function convertLngDmmData(dataByteArray) {
    if (dataByteArray.length < 4) {
        return "-";
    }
    const direction = dataByteArray[0] >> 7;
    const degrees = convertValueData([((dataByteArray[0] << 1) & 0xFE) | (dataByteArray[1] >> 7)], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const minutes = convertValueData([(dataByteArray[1] >> 1) & 0x3F], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const seconds = convertValueData([((dataByteArray[1] << 9) & 0x200) | (dataByteArray[2] << 1) | (dataByteArray[3] >> 7)], { signature: "unsigned", scaling_bit: 0.1, offset: 0, digit_after_decimal: 1 });
    if (degrees === 255 || minutes === 63 || seconds === 102.3) {
        return "-";
    } else {
        return `${degrees}° ${minutes}' ${seconds}'' ${direction === 0 ? "W" : "E"}`;
    }
}

function convertMfdBcd1Data(dataByteArray) {
    let thousandsDigitValue = 0, hundredsDigitValue = 0, tenthsDigitValue = 0, onesDigitValue = 0;

    if (dataByteArray.length === 1) {
        tenthsDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        onesDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
    } else if (dataByteArray.length === 2) {
        tenthsDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        onesDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
        thousandsDigitValue = (dataByteArray[1] >> 4).toString(16).toUpperCase();
        hundredsDigitValue = (dataByteArray[1] & 0xF).toString(16).toUpperCase();
    }
    const valueStr = `${thousandsDigitValue}${hundredsDigitValue}${tenthsDigitValue}${onesDigitValue}`;
    return convertMfdBcdValue(valueStr, 0);
}

function convertMfdBcd2Data(dataByteArray) {
    let tenThousandsDigitValue = 0, thousandsDigitValue = 0, hundredsDigitValue = 0;
    let tenthsDigitValue = 0, onesDigitValue = 0, decimalOneDigitValue = 0;
    let decimalPoints = 0;

    if (dataByteArray.length === 2) {
        decimalPoints = 1;
        onesDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        decimalOneDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
        tenthsDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
    } else if (dataByteArray.length === 3) {
        tenthsDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        onesDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
        thousandsDigitValue = (dataByteArray[1] >> 4).toString(16).toUpperCase();
        hundredsDigitValue = (dataByteArray[1] & 0xF).toString(16).toUpperCase();
        tenThousandsDigitValue = (dataByteArray[2] & 0xF).toString(16).toUpperCase();
    }
    const valueStr = `${tenThousandsDigitValue}${thousandsDigitValue}${hundredsDigitValue}${tenthsDigitValue}${onesDigitValue}.${decimalOneDigitValue}`;
    return convertMfdBcdValue(valueStr, decimalPoints);
}

function convertMfdBcdValue(valueStr, decimalPoints) {
    let updatedValueStr = "";
    let noNumbersUpperDigits = true;
    for (let index = 0; index < valueStr.length; index++) {
        const charCode = valueStr.charCodeAt(index);
        if (charCode >= 65 && charCode <= 69) {
            // A to E: Convert to 0
            updatedValueStr += "0";
        } else {
            if (valueStr[index] === "F") {
                if (noNumbersUpperDigits && index !== (valueStr.length - 1)) {
                    // index !== (valueStr.length - 1) -> Not the lowest digit
                    updatedValueStr += "-";
                } else {
                    updatedValueStr += "0";
                }
            } else {
                updatedValueStr += valueStr[index];
            }
            if (charCode >= 49 && charCode <= 57) {
                // From 1 to 9
                noNumbersUpperDigits = false;
            }
        }
    }

    const hyphenIndex = updatedValueStr.indexOf("-");
    if (hyphenIndex !== -1) {
        updatedValueStr = updatedValueStr.substring(hyphenIndex);
    }

    if (updatedValueStr === "-0") {
        return "0";
    } else if (updatedValueStr === "-0.0") {
        return "0.0";
    }
    if (decimalPoints === 0) {
        return parseInt(updatedValueStr, 10).toString(10);
    } else {
        return parseFloat(updatedValueStr).toFixed(decimalPoints);
    }
}

function convertAcl2IncValue(dataByteArray, conversionInfo) {
    // Signedを考慮した物理変換
    const intValue = convertHexToInt(dataByteArray, conversionInfo.signature);
    // 加速度を勾配[%]に変換
    let convertedValue = 100 * Math.tan(Math.asin(0.1 / 9.8 * intValue));
    // 変換結果の上限値を100[%]、下限値を-100[%]とする
    convertedValue = Math.max(Math.min(convertedValue, 100), -100);

    // Return Format number using fixed-point notation.
    return convertedValue.toFixed(conversionInfo.digit_after_decimal).toString(10);
}

export default {
    convertDataToDisplayValue,
    convertHexData,
    convertValueData,
    convertUnitValueData,
    convertPhysicalValueData
};