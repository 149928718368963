/* eslint-disable complexity, complexity, max-lines */
import api from "@/common/api";
import alert from "../../../share/alert";
import config from "../../../share/config";
import session from "../../../share/session";
import individualDiagnose from "../../../share/util/individualDiagnose";
import dataConv from "../dataMonitor/dmDidDataConversion";
import {
  API_TYPE,
  DATA_TYPE,
  ERR_KEY,
  ERR_TYPE,
  FLOW,
  I18N_PARENT_ID,
} from "./individualActiveTestConstants";

// 要求IDで可変入力するか判定
// export function variableInputByReqId(requirementId) {
//   const VARIABLE = [
//     利用する際に対象の要求IDを追加する
//   ];
//   return VARIABLE.some((e) => e === requirementId);
// }
// 要求IDで実行監視するか判定
// export function checkStartByReqId(requirementId) {
//   const WATCH_START = [
//     利用する際に対象の要求IDを追加する
//   ];
//   return WATCH_START.some((e) => e === requirementId);
// }
// 要求IDで停止するか判定
// export function stopByReqId(requirementId) {
//   const STOP = [
//     利用する際に対象の要求IDを追加する
//   ];
//   return STOP.some((e) => e === requirementId);
// }
// 要求IDで停止監視するか判定
// export function checkStopByReqId(requirementId) {
//   const WATCH_STOP = [
//     利用する際に対象の要求IDを追加する
//   ];
//   return WATCH_STOP.some((e) => e === requirementId);
// }
// 要求IDでデータ読出しするか判定
export function needsReadByReqId(requirementId) {
  const NEEDS = [
    "REQ.ACT.2F.IN.EXM.STP.STM.RD.NLP",
    "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.NLP",
    "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.LP",
    "REQ.ACT.2F.NIN.EXM.STP.STM.RD.NLP",
    "REQ.ACT.SP9",
    "REQ.ACT.SP14",
    "REQ.ACT.SP17",
    "REQ.ACT.SP4",
    "REQ.ACT.SP7",
  ];
  return NEEDS.some((e) => e === requirementId);
}
// 要求IDで連続要求するか判定
// export function repeatReadByReqId(requirementId) {
//   const REPEAT = [
//     利用する際に対象の要求IDを追加する
//   ];
//   return REPEAT.some((e) => e === requirementId);
// }

/**
 * エラーキーを取得
 * @param {*} async_code
 * @returns ERR_KEY
 */
export function getErrKeyByAsyncCd(async_code) {
  const errKey = Object.keys(ERR_KEY).find((key) => {
    ERR_KEY[key].includes(async_code);
  });
  if (errKey) return ERR_KEY[errKey];
  else return null;
}

/**
 * エラーメッセージを表示
 * @param {*} self thisを設定
 * @param {*} action エラー種類（ERR_KEY）
 * @param {*} requirementId 機能要求ID
 * @param {*} atMessage エラーメッセージ(STATUSのみ)
 * @param {() => void} [afterMethod=() => {}] OKボタン押下後に実行するメソッド
 */
export function showUnexpectedError(
  self,
  action,
  requirementId,
  atMessage,
  afterMethod = () => { }
) {
  let message;
  switch (action) {
    case ERR_KEY.INIT:
      // 初期化通信エラー
      message = self.$t("individual.error_Initialize_communication_error");
      break;
    case ERR_KEY.COMMON:
      message = self.$t("individual.error_communication_error");
      break;
    case ERR_KEY.COMMUNICATION:
      message = self.$t("individual.error_communication_error");
      break;
    case ERR_KEY.STATUS:
      message = getStatusErrMsg(self, requirementId, atMessage);
      break;
    case ERR_KEY.NRC:
      message = getNrcErrMsg(self, requirementId);
      break;
    case ERR_KEY.STOP_NRC:
      message = getStopNrcErrMsg(self, requirementId);
      break;
    default:
      message = self.$t("individual.error_communication_error");
      break;
  }
  alert.showError(self, null, message, afterMethod);
}

function getStatusErrMsg(self, requirementId, atMessage) {
  let message;
  switch (requirementId) {
    case "REQ.ACT.SP5":
      message =
        self.$t("individual.error_active_test_pre") +
        self.$t("individual.error_active_test_status_sp5");
      break;
    case "REQ.ACT.SP7":
      // ＜アクティブテスト文言1～2byte目＞
      // ＜アクティブテスト文言3～4byte目＞
      message = atMessage;
      break;
    case "REQ.ACT.2F.IN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.NLP":
    case "REQ.ACT.2F.NIN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.31.NIN.EXM.STP.STM.NRD.NLP":
    case "REQ.ACT.SP10":
    case "REQ.ACT.SP14":
    case "REQ.ACT.SP19":
    case "REQ.ACT.SP24":
    case "REQ.ACT.SP25":
      message =
        self.$t("individual.error_active_test_pre") + atMessage;
      break;
    default:
      message = "";
      break;
  }
  return message;
}

function getNrcErrMsg(self, requirementId) {
  let message;
  switch (requirementId) {
    case "REQ.ACT.SP10":
    case "REQ.ACT.SP14":
    case "REQ.ACT.SP22":
    case "REQ.ACT.SP23":
      message =
        self.$t("individual.error_active_test_pre") +
        self.$t("individual.error_active_test_cannot_exe");
      break;
    case "REQ.ACT.SP24":
    case "REQ.ACT.SP25":
      message =
        self.$t("individual.error_active_test_pre") +
        self.$t("individual.error_active_test_not_exist_by_support");
      break;
    case "REQ.ACT.SP29":
      message =
        self.$t("individual.error_active_test_pre") +
        self.$t("individual.error_active_test_nrc_sp29_drive");
      break;
    default:
      message = "";
      break;
  }
  return message;
}

function getStopNrcErrMsg(self, requirementId) {
  let message;
  switch (requirementId) {
    case "REQ.ACT.SP29":
      message =
        self.$t("individual.error_active_test_pre") +
        self.$t("individual.error_active_test_nrc_sp29_stop");
      break;
    default:
      message = "";
      break;
  }
  return message;
}

/**
 * アクティブテスト文言取得
 * @param {*} self
 * @param {*} systemId
 * @param {*} requirementId
 * @param {number} statusVal AT実行APIのレスポンスのuser_data.valueから取得した監視条件の10進値
 * @param {boolean} isDrive 駆動/停止
 * @param {*} [getMsg=()=>{}] メッセージ取得用メソッド
 * @param {boolean} [isSpecial=false] 特殊条件か(IOCP&RoutineStatus表を見るか等)
 * @returns {string} アクティブテスト文言
 */
export function getAtMsg(
  self,
  systemId,
  requirementId,
  statusVal,
  isDrive,
  getMsg = () => { },
  isSpecial = false
) {
  let message;
  const ecuKey = "at" + systemId + ".999999.STATUS_" + statusVal;
  switch (requirementId) {
    // 固定値
    case "REQ.ACT.2F.NIN.EXM.STP.STM.RD.NLP":
      message = (!isDrive && !isSpecial) // 停止かつisSpecial=false
        ? self.$t("individual.label_active_test_message_com5_stop")
        : self.$te(ecuKey) ? self.$t(ecuKey) : "";
      break;
    case "REQ.ACT.2F.NIN.EXM.STP.NSTM.NRD.LP":
      message = isDrive
        ? self.$t("individual.label_active_test_message_com6_drive")
        : self.$t("individual.label_active_test_message_com6_stop");
      break;
    case "REQ.ACT.31.NIN.EXM.STP.STM.NRD.NLP":
      message = (!isDrive && !isSpecial) // 停止かつisSpecial=false
        ? self.$t("individual.label_active_test_message_com7_stop")
        : self.$te(ecuKey) ? self.$t(ecuKey) : "";
      break;
    case "REQ.ACT.31.NIN.NEXM.NSTP.NSTM.NRD.NLP":
      message = isDrive
        ? self.$t("individual.label_active_test_message_com10_drive")
        : self.$t("individual.label_active_test_message_com10_stop");
      break;
    case "REQ.ACT.SP4":
      message = self.$te(ecuKey) ? self.$t(ecuKey) : "";
      break;
    case "REQ.ACT.SP16":
      message = isDrive
        ? self.$t("individual.label_active_test_message_sp16_drive")
        : self.$t("individual.label_active_test_message_sp16_stop");
      break;
    case "REQ.ACT.SP17":
      message = isDrive
        ? self.$t("individual.label_active_test_message_sp17_drive")
        : self.$t("individual.label_active_test_message_sp17_stop");
      break;
    case "REQ.ACT.SP19":
      message = (!isDrive && !isSpecial) // 停止かつ停止監視継続条件ではない
        ? self.$t("individual.label_active_test_message_sp19_stop")
        : self.$t("individual.label_active_test_message_sp19_on");
      break;
    case "REQ.ACT.SP23":
      message = (!isDrive && isSpecial) // 停止かつisSpecial=true
        ? self.$t("individual.label_active_test_message_sp23_stop")
        : self.$te(ecuKey) ? self.$t(ecuKey) : "";
      break;
    case "REQ.ACT.SP24":
      message = (!isDrive && isSpecial) // 停止かつisSpecial=true
        ? self.$t("individual.label_active_test_message_sp24_stop")
        : self.$te(ecuKey) ? self.$t(ecuKey) : "";
      break;
    case "REQ.ACT.SP25":
      message = (!isDrive && isSpecial) // 停止かつIOCP&RoutineStatus表から取得
        ? self.$t("individual.label_active_test_message_sp25_stop")
        : self.$te(ecuKey) ? self.$t(ecuKey) : "";
      break;
    case "REQ.ACT.SP29":
      message = isDrive
        ? getMsg()
        : self.$t("individual.label_active_test_message_sp29_stop");
      break;
    default:
      // 変換値(IOCPから取得したapiResに対応した文言を取得)
      message = self.$te(ecuKey) ? self.$t(ecuKey) : "";
      break;
  }
  return message;
}

/**
 * ステータスメッセージの取得
 * @param {*} self 
 * @param {*} systemId 
 * @param {number} statusVal AT実行APIのレスポンスのuser_data.valueから取得した監視条件の10進値
 * @returns {string} STATUSメッセージ
 */
export function getStatusMsg(self, systemId, statusVal) {
  const ecuKey = "at" + systemId + ".999999.STATUS_" + statusVal;
  return self.$te(ecuKey) ? self.$t(ecuKey) : "";
}

/**
 * 正常異常判定に使用するユーザーデータのインデックスを取得する。
 * @param {*} requirementId 機能要求ID
 * @param {*} errType エラータイプ
 * @returns インデックス
 */
export function getUserDataIdx(requirementId, errType = null) {
  let index = 0;
  switch (errType) {
    case ERR_KEY.COMMON:
      break;
    case ERR_KEY.COMMUNICATION:
      switch (requirementId) {
        // 必要ならパターン追加 (以下追加例)
        // case "REQ.ACT.SP25":
        //   index = 1;
        //   break;
        default:
          index = 0;
      }
      break;
    case ERR_KEY.INIT:
      break;
    case ERR_KEY.NRC:
      switch (requirementId) {
        // 必要ならパターン追加
        default:
          index = 0;
      }
      break;
    case ERR_KEY.STATUS:
      switch (requirementId) {
        // 必要ならパターン追加
        default:
          index = 0;
      }
      break;
    case ERR_KEY.STOP_NRC:
      break;
    default:
      switch (requirementId) {
        // 共通パターンはすべてdefault。
        default:
          index = 0;
      }
  }
  return index;
}

/**
 * AT実行APIのレスポンスのuser_data.valueから監視条件を取得
 * @param {string} hex AT実行APIのレスポンスのuser_data.value
 * @param {string} reqId 機能要求ID
 * @returns {string} 監視条件のバイト文字列
 */
export function getMonitoringStatus(hex, reqId) {
  switch (reqId) {
    case "REQ.ACT.2F.IN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.NLP":
    case "REQ.ACT.2F.NIN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.NIN.EXM.STP.NSTM.NRD.LP":
    case "REQ.ACT.SP9":
    case "REQ.ACT.SP10":
    case "REQ.ACT.SP14":
    case "REQ.ACT.SP16":
    case "REQ.ACT.SP17":
    case "REQ.ACT.SP29":
      // 4バイト目以降を切り出し
      return hex.slice(6);
    default:
      // 5バイト目以降を切り出し
      return hex.slice(8);
  }
}

/**
 * AT実行APIのレスポンスのuser_data.valueからNRCエラーの状態を取得
 * @param {string} hex AT実行APIのレスポンスのuser_data.value
 * @returns {string} NRCエラーの状態
 */
export function getNRCStatus(hex) {
  // 3バイト目を切り出し
  return hex.slice(4, 6);
}

/**
 * AT実行(standby)APIのrequestBody生成
 * @param {*} idToken 識別トークン
 * @param {boolean} isMeasuring DM計測中フラグ
 * @returns 
 */
export function genReqBodyStandby(idToken, isMeasuring) {
  const request = {
    id_token: idToken,
    is_measuring: isMeasuring,
    type: API_TYPE.STANDBY,
  };
  return request;
}

/**
 * AT実行(terminate)APIのrequestBody生成
 * @param {*} idToken 識別トークン
 * @param {*} isError errorTerminateを呼ぶか？
 * @returns terminate時のリクエストボディ
 */
export function genReqBodyTerminate(idToken, isError) {
  const apiType = isError ? API_TYPE.ERR_TERMINATE : API_TYPE.TERMINATE;
  const request = {
    id_token: idToken,
    type: apiType,
  };
  return request;
}

/**
 * AT開始APIのrequestBody生成
 * @param {*} idToken 識別トークン
 * @param {*} selectedItem 選択された項目(menusで取得)
 * @param {*} controlStateVal ユーザ入力値
 * @param {number} [index=0] 選択したSIDのインデックス
 * @returns terminate時のリクエストボディ
 */
export function genReqBodyStart(idToken, selectedItem, controlStateVal, index = 0) {
  let request = null;
  switch (selectedItem.requirement_id) {
    case "REQ.ACT.2F.IN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.NLP":
    case "REQ.ACT.2F.NIN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.NIN.EXM.STP.NSTM.NRD.LP":
    case "REQ.ACT.SP7":
    case "REQ.ACT.SP9":
    case "REQ.ACT.SP10":
    case "REQ.ACT.SP14":
    case "REQ.ACT.SP16":
    case "REQ.ACT.SP17":
    case "REQ.ACT.SP29":
      request = {
        id_token: idToken,
        did: selectedItem.sid0x2E_sid0x2F[index],
        control_state: controlStateVal,
        type: API_TYPE.START,
      };
      break;
    case "REQ.ACT.SP5":
      request = {
        id_token: idToken,
        rid: selectedItem.rid[index],
        routine_control_option_record: controlStateVal,
        type: API_TYPE.START,
      };
      break;
    case "REQ.ACT.31.NIN.EXM.STP.STM.NRD.NLP":
    case "REQ.ACT.31.NIN.NEXM.NSTP.NSTM.NRD.NLP":
    case "REQ.ACT.SP4":
    case "REQ.ACT.SP19":
    case "REQ.ACT.SP20":
    case "REQ.ACT.SP22":
    case "REQ.ACT.SP23":
    case "REQ.ACT.SP25":
    case "REQ.ACT.SP26":
    case "REQ.ACT.SP27":
      request = {
        id_token: idToken,
        rid: selectedItem.rid[index],
        type: API_TYPE.START,
      };
      break;
    case "REQ.ACT.SP24":
      request = {
        id_token: idToken,
        rid: selectedItem.values[0][index].rid[0],
        type: API_TYPE.START,
      };
      break;
    default:
      break;
  }
  return request;
}

/**
 * AT開始APIのrequestBody生成
 * @param {*} idToken 識別トークン
 * @param {*} selectedItem 選択された項目(menusで取得)
 * @param {number} [index=0] 選択したSIDのインデックス
 * @param {string} [errType=""] エラータイプ(./individualActiveTestConstants を参照)
 * @returns terminate時のリクエストボディ
 */
export function genReqBodyStop(idToken, selectedItem, index = 0, errType = "") {
  let request = null;
  switch (selectedItem.requirement_id) {
    case "REQ.ACT.2F.IN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.NLP":
    case "REQ.ACT.2F.NIN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.NIN.EXM.STP.NSTM.NRD.LP":
    case "REQ.ACT.SP9":
    case "REQ.ACT.SP10":
    case "REQ.ACT.SP14":
    case "REQ.ACT.SP16":
    case "REQ.ACT.SP17":
      request = {
        id_token: idToken,
        did: selectedItem.sid0x2E_sid0x2F[index],
        type: API_TYPE.STOP,
      };
      break;
    case "REQ.ACT.SP29":
      switch (errType) {
        case ERR_TYPE.COMMUNICATE:
        case ERR_TYPE.NRC:
          request = {
            id_token: idToken,
            did: selectedItem.sid0x2E_sid0x2F[index],
            type: API_TYPE.STOP,
            error_type: errType,
          };
          break;
        default:
          request = {
            id_token: idToken,
            did: selectedItem.sid0x2E_sid0x2F[index],
            type: API_TYPE.STOP,
          };
          break;
      }
      break;
    case "REQ.ACT.31.NIN.EXM.STP.STM.NRD.NLP":
    case "REQ.ACT.SP4":
    case "REQ.ACT.SP5":
    case "REQ.ACT.SP19":
    case "REQ.ACT.SP22":
    case "REQ.ACT.SP23":
    case "REQ.ACT.SP25":
    case "REQ.ACT.SP26":
    case "REQ.ACT.SP27":
      request = {
        id_token: idToken,
        rid: selectedItem.rid[index],
        type: API_TYPE.STOP,
      };
      break;
    case "REQ.ACT.SP7":
      switch (errType) {
        case ERR_TYPE.COMMUNICATE:
        case ERR_TYPE.NRC:
          request = {
            id_token: idToken,
            did: selectedItem.sid0x2E_sid0x2F,
            type: API_TYPE.STOP,
            error_type: errType,
          };
          break;
        default:
          request = {
            id_token: idToken,
            did: selectedItem.sid0x2E_sid0x2F,
            type: API_TYPE.STOP,
          };
          break;
      }
      break;
    case "REQ.ACT.SP20":
      switch (errType) {
        case ERR_TYPE.COMMUNICATE:
          request = {
            id_token: idToken,
            rid: selectedItem.rid[index],
            type: API_TYPE.STOP,
            error_type: errType,
          };
          break;
        default:
          request = {
            id_token: idToken,
            rid: selectedItem.rid[index],
            type: API_TYPE.STOP,
          };
      }
      break;
    case "REQ.ACT.SP24":
      request = {
        id_token: idToken,
        rid: selectedItem.values[0][index].rid[0],
        type: API_TYPE.STOP,
      };
      break;
    default:
      break;
  }
  return request;
}

/**
 * アクティブテストの各 requirement 関連のポーリング処理共通関数
 * - ※パラメータの詳細は、jsdoc を参照すること（individualDiagnose.js の上部にある各型の説明で Callback の詳細も記載している）
 * @param {object} self 呼び出し元の this を指定する
 * @param {string} netAppId クラウドコネクター ID
 * @param {string} systemId システム ID
 * @param {string} activeTestId アクティブテスト（不変） ID
 * @param {object} requestBody POST リクエストの body
 * @param {import('./individualDiagnose').CallbackError} errorCallback エラー発生のコールバック関数
 * @param {import('./individualDiagnose').CallbackAPIResponseError} checkPostApiErrorResultCallback POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {import('./individualDiagnose').CallbackAPIResponseError} checkGetApiErrorResultCallback GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {CallbackFunc} inProgressCallback アクティブテスト実行API(GET)のレスポンスチェックがin_progress時のコールバックエラー発生のコールバック関数
 * @param {CallbackFunc} completedCallback アクティブテスト実行API(GET)レスポンスチェックがcompleted時のコールバック
 * @param {CallbackFunc} failedCallback アクティブテスト実行API(GET)のレスポンスチェックがfailed時のコールバック
 * @param {CallbackFunc} postCheckCallback アクティブテスト実行API(POST)のレスポンスのコールバック
 * @param {number} pollingRetryCount Option: ポーリングのリトライ回数指定（指定なし、もしくは0の場合は、無限とする）
 * @param {number} pollingRetryInterval Option: ポーリングのリトライ待機時間指定（指定なしの場合は、アクティブテスト独自の環境変数の値を使用）
 * @param {boolean} pollingStopTimeOutFlg Option: ポーリング時のタイムアウト停止フラグ（指定なしの場合は、タイムアウト停止する（true））
 * @returns {object} 最終的な GET レスポンス（エラー発生時は null になる）
 */
export async function pollingAtApi(
  self,
  netAppId,
  systemId,
  activeTestId,
  requestBody,
  errorCallback = (errorType) => {
    errorType;
  },
  checkPostApiErrorResultCallback = (result) => {
    result;
    errorCallback(config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE);
  },
  checkGetApiErrorResultCallback = (result) => {
    result;
    errorCallback(config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE);
  },
  inProgressCallback = () => {
    config.INDIVIDUAL_POLLING_RESULT_CONTINUE;
  },
  completedCallback = () => {
    config.INDIVIDUAL_POLLING_RESULT_FINISH;
  },
  failedCallback = () => {
    config.INDIVIDUAL_POLLING_RESULT_FINISH;
  },
  postCheckCallback = () => { ""; },
  pollingRetryCount = Number.MAX_SAFE_INTEGER,
  pollingRetryInterval = process.env.VUE_APP_ACTIVE_TEST_RETRY_INTERVAL,
  pollingStopTimeOutFlg = true,
) {
  const pollingRetryCountAt = pollingRetryCount > 0 ? pollingRetryCount : Number.MAX_SAFE_INTEGER;
  return await individualDiagnose.pollingApi(
    self,
    // POST API 呼び出しのコールバック
    async () => {
      // アクティブテスト実行(駆動開始,駆動停止,データ読み出し)
      return await api.postCall(
        config.DMAT,
        "/netApps/" +
        netAppId +
        "/systems/" +
        systemId +
        "/activeTest/" +
        activeTestId,
        requestBody
      );
    },
    // GET API 呼び出しのコールバック
    async (postApiResponse) => {
      // startならメモIDを取得
      if (
        API_TYPE.START === requestBody.type &&
        self.measured_id !== undefined
      ) {
        self.measured_id = postApiResponse.data.measures_id;
      }
      // アクティブテスト実行結果確認
      return await api.getCall(
        config.DMAT,
        "/netApps/" +
        netAppId +
        "/systems/" +
        systemId +
        "/activeTest/" +
        activeTestId +
        "/requests/" +
        postApiResponse.data.request_id
      );
    },
    // エラー発生のコールバック
    (errorType) => {
      errorCallback(errorType);
    },
    // POST API レスポンスチェックのコールバック
    (postApiResponse) => {
      // POST API レスポンスのコールバック
      postCheckCallback(postApiResponse, self);
      // リクエストID が正常に返ってきたら成功とする
      return postApiResponse &&
        postApiResponse.data &&
        postApiResponse.data.request_id
        ? ""
        : config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE;
    },
    // GET API レスポンスチェックのコールバック
    (getApiResponse) => {
      let result = config.INDIVIDUAL_POLLING_RESULT_ERROR;
      if (getApiResponse && getApiResponse.data && getApiResponse.data.status) {
        const resultList = [
          config.INDIVIDUAL_POLLING_RESULT_ERROR,
          config.INDIVIDUAL_POLLING_RESULT_FINISH,
          config.INDIVIDUAL_POLLING_RESULT_CONTINUE,
        ];
        let resTmp = null;
        switch (getApiResponse.data.status) {
          case config.STATUS_DMAT_COMPLETED:
            result = config.INDIVIDUAL_POLLING_RESULT_FINISH;
            // completed時のコールバック
            resTmp = completedCallback(getApiResponse, self);
            resultList.includes(resTmp) && (result = resTmp);
            break;
          case config.STATUS_DMAT_FAILED:
            // failed でも呼び出し元でハンドリングしたいケースがあるため、いずれもポーリング終了を返す
            result = config.INDIVIDUAL_POLLING_RESULT_FINISH;
            // failed時のコールバック
            resTmp = failedCallback(getApiResponse, self);
            resultList.includes(resTmp) && (result = resTmp);
            break;
          case config.STATUS_DMAT_IN_PROGRESS:
            // ポーリング続行を返す
            result = config.INDIVIDUAL_POLLING_RESULT_CONTINUE;
            // in_progress時のコールバック
            resTmp = inProgressCallback(getApiResponse, self);
            resultList.includes(resTmp) && (result = resTmp);
            break;
          default:
            // それ以外の場合はエラー発生を返す（エラーコールバックが呼ばれる）
            break;
        }
      }
      return result;
    },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => {
      checkPostApiErrorResultCallback(result);
    },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => {
      checkGetApiErrorResultCallback(result);
    },
    // ポーリングのリトライ回数指定（指定なし、もしくは0の場合は、無限とする）
    pollingRetryCountAt,
    // ポーリングのリトライ待機時間指定（指定なしの場合は、アクティブテスト独自の環境変数の値を使用）
    pollingRetryInterval,
    //ポーリング時のタイムアウト停止フラグ（指定なしの場合は、タイムアウト停止する（true））
    pollingStopTimeOutFlg
  );
}

/**
 * API汎用処理（standby, terminate, errorTerminate）
 * standby: NonDefaultSession遷移・セキュリティアクセス処理
 * terminate: DefaultSession遷移
 * errorTerminate: DefaultSession遷移
 * @param {object} self 呼び出し元の this を指定する
 * @param {*} requestBody リクエストボディ
 */
export async function doCommonAtApi(self, requestBody) {
  let response = null;
  try {
    // API実行
    response = await pollingAtApi(
      self,
      self.netAppId,
      self.systemId,
      self.activeTestId,
      requestBody,
      // エラー発生のコールバック
      (errorType) => {
        errorType;
        showUnexpectedError(self);
      },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
      (result) => {
        validateErrorResult(self, result);
      },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
      (result) => {
        validateErrorResult(self, result);
      }
    );

    if (response) {
      switch (response.data.status) {
        case config.STATUS_DMAT_COMPLETED:
          break;
        case config.STATUS_DMAT_FAILED:
          showUnexpectedError(self);
          break;
        default:
          showUnexpectedError(self);
          break;
      }
    }
  } catch (e) {
    showUnexpectedError(self);
  }
  return response;
}

/** 
 * データ読出し
 * @param {*} self 
 * @param {array} didList データ読み出しを行うDIDのリスト
 * @returns データ読み出しのレスポンス
 */
export async function doRead(self, didList, pollingRetryCount = 0) {
  // API実行パラメータ作成
  const token = session.getToken();
  const requestBody = {
    id_token: token.id_token,
    did: didList,
    type: API_TYPE.READ,
  };

  // read API実行
  const response = await pollingAtApi(
    self,
    self.netAppId,
    self.systemId,
    self.activeTestId,
    requestBody,
    // エラー発生のコールバック
    (errorType) => {
      errorType;
      showUnexpectedError(self);
    },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => {
      validateErrorResult(self, result);
    },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => {
      validateErrorResult(self, result);
    },
    undefined, // inProgressCallback
    undefined, // completedCallback
    undefined, // failedCallback
    undefined, // postCheckCallback
    pollingRetryCount
  );
  if (response) {
    switch (response.data.status) {
      case config.STATUS_DMAT_COMPLETED:
        // 正常終了
        break;
      case config.STATUS_DMAT_FAILED:
        // 異常終了
        break;
      default:
        showUnexpectedError(self);
        break;
    }
  }
  return response;
}

/**
 * AT実行API(standby)
 * @param {*} self 呼び出し元の this を指定する
 * @param {boolean} isMeasuring DM計測中フラグ
 */
export async function doStandby(self, isMeasuring) {
  const token = session.getToken();
  const reqBody = genReqBodyStandby(
    token.id_token,
    isMeasuring
  );
  return await doCommonAtApi(self, reqBody);
}

/**
 * AT実行API(terminate, errorTerminate)
 * @param {*} self 呼び出し元の this を指定する
 * @param {boolean} isError errorTerminateを呼ぶか？
 */
export async function doTerminate(self, isError) {
  const token = session.getToken();
  if (!token) {
    return null;
  }
  const reqBody = genReqBodyTerminate(
    token.id_token,
    isError
  );
  return await doCommonAtApi(self, reqBody);
}

/**
 * 変換情報取得処理
 * @param {object} self 呼び出し元の this を指定する
 * @param {array} at_id_list 選択項目の不変idList
 * @returns {array} conversionInfo 変換情報
 */
export async function getATConversion(self, at_id_list) {
  const response = await individualDiagnose.syncApi(
    self,
    // GET API 呼び出しのコールバック
    async () => {
      return await api.getParamCall(
        config.DMAT,
        "/netApps/" +
        self.netAppId +
        "/systems/" +
        self.systemId +
        "/activeTestConversion",
        {
          active_test_id_list: JSON.stringify(at_id_list),
        }
      );
    },
    // エラー発生のコールバック
    (errorType) => {
      errorType;
      showUnexpectedError(self);
    },
    // GET API レスポンスチェックのコールバック
    (getApiResponse) => {
      // monitor_items が入っていれば成功とする
      return getApiResponse &&
        getApiResponse.data &&
        getApiResponse.data.conversion_list
        ? ""
        : config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE;
    },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => {
      validateErrorResult(self, result);
    }
  );

  return response;
}

/**
 * API通信で共通バリデーションでエラーが発生した際のコールバック
 * @param {*} self 呼び出し元の this を指定する
 * @param {object} result バリデーション結果
 */
export function validateErrorResult(self, result) {
  if (self.signout) {
    return;
  }

  individualDiagnose.apiErrorDefaultAction(
    self,
    result,
    // ログアウト処理のコールバック
    (self) => {
      self;
      // 駆動中、計測中状態を解除
      self.$emit("toggle-drive", false);
      self.$emit("toggle-execute", false);
      self.logout(self);
    },
    // セッションリセット処理のコールバック
    (self) => {
      self;
      // 駆動中、計測中状態を解除
      self.$emit("toggle-drive", false);
      self.$emit("toggle-execute", false);
      self.resetSession(self);
    },
    // その他エラー発生のコールバック
    (result) => {
      result;
      showUnexpectedError(self);
    }
  );
}

/**
 * バイトデータを1バイトごとに整数に変化し、配列で返却
 * @param {string} dataByte バイトデータ
 * @param {int} result 整数の配列
 */
export function convertBytesToIntArray(dataByte, conversionInfo) {
  const intArray = [];

  if (conversionInfo.info.byte_or_bit === "byte") {
    for (let i = 0; i < dataByte.length; i += 2) {
      intArray.push(parseInt(dataByte.substring(i, i + 2), 16));
    }
  } else {
    intArray.push(parseInt(dataByte), 16);
  }

  return intArray;
}

/** 
 * バイト文字列をDID変換
 * @param {string} value バイト文字列
 * @param {object} conversionInfo 変換情報
 * @returns {string} バイト文字列をDID変換した値
 */
export function convCtrlStateValue(value, conversionInfo) {
  // リトルエンディアンの場合、バイトデータを逆順に並び替え
  const reverseValue = reverseBytes(
    value,
    conversionInfo
  );

  // バイトデータを配列に分解
  const convertedIntArray = convertBytesToIntArray(
    reverseValue,
    conversionInfo
  );

  // DID定義に従いデータ変換
  const convertedValue = dataConv.convertDataToDisplayValue(
    convertedIntArray,
    conversionInfo.info
  );

  return convertedValue;
}

/** 
 * リトルエンディアンの場合、バイトデータをリバースする
 * @param {string} value バイト文字列
 * @param {object} conversionInfo 変換情報
 * @returns {string} バイトデータをリバースした値
 */
export function reverseBytes(value, conversionInfo) {
  if (!conversionInfo.info.little_endian) {
    return value;
  }

  let reverseValue = "";
  for (let i = value.length; i > 0; i -= 2) {
    reverseValue += value.substring(i, i - 2);
  }

  return reverseValue;
}

/**
 * 変換情報をもとに数値をHEXに逆変換します。
 * @param {Number} value 変換前の数値
 * @param {*} conversionInfo 変換情報
 * @returns 数値から逆変換したHEX
 */
export function convertValueToHex(value, conversionInfo) {
  // offsetとscaling bitを計算する
  let tmp = (value - conversionInfo.info.offset) / conversionInfo.info.scaling_bit;
  // 整数化 ※小数点以下は四捨五入
  tmp = Math.round(tmp);

  // 符号付き16進数に変換する
  let ret = convertToSignedHex(tmp, conversionInfo);

  // リトルエンディアンの場合はバイト反転
  if (conversionInfo.info.little_endian) {
    ret = reverseBytes(ret, conversionInfo);
  }

  return ret;
}

/**
 * 数値を符号付き16進数に変換する
 * @param {Number} value 変換前の数値
 * @param {*} conversionInfo 変換情報
 * @returns 数値を符号付き16進数に変換した値
 */
function convertToSignedHex(value, conversionInfo) {
  const byteSize = conversionInfo.info.byte_or_bit === "byte"
    ? conversionInfo.info.byte_size
    : Math.ceil(conversionInfo.info.bit_size / 8);
  const bitSize = byteSize * 8;

  let tmp = value;

  // 符号付きで負の数は2の補数に変換する
  if (conversionInfo.info.signature === "signed" && tmp < 0) {
    tmp = tmp + Math.pow(2, bitSize);
    tmp = tmp.toString(2).padStart(bitSize, "1");
  } else {
    tmp = tmp.toString(2).padStart(bitSize, "0");
  }

  // 4bitごとに16進文字列に変換
  let ret = "";
  for (let i = 0; i < byteSize * 2; i++) {
    const bit4 = tmp.slice(i * 4, (i + 1) * 4);
    ret += parseInt(bit4, 2).toString(16);
  }

  return ret.toUpperCase();
}

/**
 * 駆動ストップ時のアクティブ文言のクリア判定を行う
 * @param {string} requirementId 要求ID
 * @returns true：クリアする false：クリアしない
 */
function isClearMessageOnStop(requirementId) {
  let isClear = false;

  switch (requirementId) {
    case "REQ.ACT.2F.IN.EXM.STP.STM.RD.NLP":
    case "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.NLP":
    case "REQ.ACT.SP4":
    case "REQ.ACT.SP5":
    case "REQ.ACT.SP9":
    case "REQ.ACT.SP10":
    case "REQ.ACT.SP14":
    case "REQ.ACT.SP26":
    case "REQ.ACT.SP27":
      isClear = true;
      break;
    default:
      break;
  }

  return isClear;
}

export default {
  showUnexpectedError,
  // checkStartByReqId,
  // stopByReqId,
  // checkStopByReqId,
  needsReadByReqId,
  // repeatReadByReqId,
  // variableInputByReqId,
  pollingAtApi,
  doCommonAtApi,
  doRead,
  doStandby,
  doTerminate,
  getATConversion,
  getAtMsg,
  getStatusMsg,
  getUserDataIdx,
  getMonitoringStatus,
  getNRCStatus,
  genReqBodyStandby,
  genReqBodyStart,
  genReqBodyStop,
  genReqBodyTerminate,
  validateErrorResult,
  getErrKeyByAsyncCd,
  convertBytesToIntArray,
  convCtrlStateValue,
  convertValueToHex,
  convertToSignedHex,
  isClearMessageOnStop,
  I18N_PARENT_ID,
  API_TYPE,
  FLOW,
  ERR_KEY,
  DATA_TYPE,
};
