<div class="container-fluid ml-n2">
  <div>
    <div class="list-detail-top mt-2">
      <div>
        <b-button class="ml-2 mt-2" :disabled="!measuredId" variant="primary" size="sm" v-on:click="openNoteModal()">
          {{ $t('message.button_vehicle_dtc_note_modal') }}
        </b-button>
        <b-button class="ml-2 mt-2" :disabled="!measuredId" variant="primary" size="sm" v-on:click="showCustomReadModal()">
          {{ $t('individual.button_vehicle_individual_customize_setting_read') }}
        </b-button>
        <b-button class="ml-2 mt-2" :disabled="!measuredId" variant="primary" size="sm" v-on:click="onClickSaveSettings()">
          {{ $t('individual.button_vehicle_individual_customize_setting_save') }}
        </b-button>
      </div>
      <div>
        <b-button class="ml-2 mt-2" :disabled="!measuredId" variant="primary" size="sm" v-on:click="regetCstm()">
          {{ $t('message.button_vehicle_dtc_gain_again') }}
        </b-button>
        <b-button class="ml-2 mt-2" variant="primary" size="sm" :disabled="getChangedItemsCount() <= 0" v-on:click="updateCstmData">
          {{ $t('individual.button_vehicle_individual_customize_change_setting') }}
        </b-button>
      </div>
    </div>
  </div>

  <!-- 結果表示テーブル -->
  <cstm-table
    :items="cstmListItem" :columns="cstmTableColumns" class="list-customize mt-2 ml-2" type="simple" rowSelect currentSortkey="name" initSortDir="asc" useStripeStyle>
  </cstm-table>


  <!-- メモ画面 -->
  <b-modal v-model="modalMemo" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>

    <memo-Page v-if="modalMemo" ref="memo"
      :projectId="vehicleInfo.vehicle.project.id"
      :measureId="measuredId"
      destinationAPI="cst"
      functionNameKey="individual.individual_diagnosis_function_customize"
      @closeNoteModal="closeNoteModal"
    />

    <template v-slot:footer>
      <button type="button" v-on:click="memoClear()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill">{{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary" @click="modalMemo=false" class="ml-2 mr-2">
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button size="sm" variant="primary" @click="memoSave()" class="ml-2 mr-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 設定読み出し画面 -->
  <b-modal v-model="modalCustomRead" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-close-on-backdrop no-close-on-esc centered no-stacking hide-footer scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('individual.button_vehicle_individual_customize_setting_read') }}
      </div>
    </template>
    <div class="container-fluid">
      <div class="row">
        <!-- ユーザー設定値 -->
        <div class="col-12 mt-1">
          <div class="d-flex w-100">
            <label class="mr-2 vehicle-info-style">{{ $t(getSystemResourceKey) }}</label>
            <label v-show="vehicleDisplayInfo.specMode === 'abroad'"
              class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_vin') }} {{ vehicleDisplayInfo.vin }}</label>
            <label v-show="vehicleDisplayInfo.specMode === 'domestic'"
              class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_cn') }} {{ vehicleDisplayInfo.vin }}</label>
            <label v-show="vehicleDisplayInfo.specMode === 'abroad'"
              class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_my') }} {{ vehicleDisplayInfo.spec }}</label>
            <label v-show="vehicleDisplayInfo.specMode === 'domestic'"
              class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_cl') }} {{ vehicleDisplayInfo.spec }}</label>
          </div>
          <div class="d-flex w-100">
            <label class="vehicle-info-style"
              :class="{ 'mr-2': vehicleDisplayInfo.specMode === 'domestic' }">{{ $t('message.label_vehicle_system_model') }}
              {{ vehicleDisplayInfo.modelDisplayName }}</label>
            <label v-show="vehicleDisplayInfo.specMode === 'domestic'"
              class="vehicle-info-style">{{ $t('message.label_vehicle_system_applied_model') }} {{ vehicleDisplayInfo.appliedModel }}</label>
            <label v-if="vehicleDisplayInfo.hideRegistrationNumber" class="ml-2 mr-1 vehicle-info-style">{{ `${$t('message.label_registration_number')} :` }}</label>
            <label v-if="vehicleDisplayInfo.hideRegistrationNumber" class="vehicle-info-style">{{ vehicleDisplayInfo.registrationNumber }}</label>
          </div>
        </div>
        <!-- 検索条件 -->
        <div class="col-12 mt-1">
          <cstm-card headerTitle="message.header_measured_data_serch" :isCollapse="isSearchFieldOpened"
            accordionId="1">
            <template v-slot:body>
              <div class="row">
                <div class="col-12 d-flex mt-2">
                  <div class="d-flex w-100">
                    <!-- 登録日(年) -->
                    <div class="w-18 mr-2">
                      <b-form-group class="form-group">
                        <label>{{ $t('message.label_measured_data_search_registered_date') }}</label>
                        <b-form-select v-model="selectedYear" :options="searchYearOptions" @change="searchCustom()" class="custom-select" />
                      </b-form-group>
                    </div>
                    <!-- 登録日(月) -->
                    <div class="w-15 mr-2">
                      <b-form-group class="form-group">
                        <label>{{ $t('individual.label_custom_read_regdate_month') }}</label>
                        <b-form-select v-model="selectedMonth" :options="searchMonthOptions" @change="searchCustom()" class="custom-select" />
                      </b-form-group>
                    </div>
                    <!-- 説明 -->
                    <div class="w-60 mr-2">
                      <b-form-group class="form-group">
                        <label>{{ $t('individual.common_label_description') }}</label>
                        <b-form-input type="text" v-model="filledComment" @input="searchCustom()" />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </cstm-card>
        </div>
        <!-- 検索結果一覧 -->
        <div class="col-12 mt-1">
          <div class="project-tbl-style">
            <div class="row width-max">
              <div class="col-12 mt-2 width-max">
                <!-- TODO: v2マージ後のフルテストのタイミングでuseFixSortを削除し、他のテーブルに影響がないかを確認する -->
                <cstm-table useSort hasTabs rowSelect useFixSort initSortDir="asc" :items="customReadTable" :columns="customReadTableColumns"
                  class="mt-2 ml-2 list-scroll" type="simple" useStripeStyle @rowClicked="clickRow" @rowButtonClicked="delCustomReadList" currentSortkey="status">
                  <template v-slot:rowButton>{{ $t('individual.label_readiness_code_delete') }}
                  </template>
                </cstm-table>
              </div>
            </div>
          </div>
        </div>
        <!-- キャンセル、読み出しボタン -->
        <div class="col-12 text-center mb-4 mt-3">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" v-on:click="cstmReadClose()">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" variant="primary" class="ml-2 mr-2" :disabled="!selectedCustomRead.id" v-on:click="updCustomData()" >
            {{ $t('individual.label_custom_result_read_btn') }}
          </b-button>
        </div>
      </div>
      <processing-view :processing="processing"></processing-view>
    </div>
  </b-modal>

  <!-- 設定保存ダイアログ -->
  <b-modal v-model="modalSettingsSave" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('individual.button_vehicle_individual_customize_setting_save') }}
      </div>
    </template>

    <template v-slot:default>
      <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
        <div class="container-fluid my-2">
          <div class="row no-gutters">
            <div class="col-3 mt-2">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t('message.label_vehicle_dtc_note_modal_vin') }}</span>
              </div>
            </div>
            <div class="col-9 mt-2">
              <div>
                <span style="font-weight: bold;" class="box-margin-left">{{ vehicleDisplayInfo.vin }}</span>
              </div>
            </div>
            <div class="col-3 mt-4">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t('message.label_vehicle_dtc_note_modal_vehicle') }}</span>
              </div>
            </div>
            <div class="col-9 mt-4">
              <div>
                <label class="vehicle-info-style" :class="{ 'mr-2': vehicleDisplayInfo.specMode === 'domestic' }">{{ vehicleDisplayInfo.modelDisplayName }}</label>
                <label v-show="vehicleDisplayInfo.specMode === 'abroad'" class="mr-2 vehicle-info-style">{{ vehicleDisplayInfo.spec }}</label>
                <label v-show="vehicleDisplayInfo.specMode === 'domestic'" class="mr-2 vehicle-info-style">{{ vehicleDisplayInfo.spec }}</label>
                <label v-show="vehicleDisplayInfo.specMode === 'domestic'" class="vehicle-info-style">{{ vehicleDisplayInfo.appliedModel }}</label>
              </div>
            </div>
            <div v-if="vehicleDisplayInfo.hideRegistrationNumber" class="col-3 mt-4">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t('message.label_vehicle_dtc_note_modal_number') }}</span>
              </div>
            </div>
            <div v-if="vehicleDisplayInfo.hideRegistrationNumber" class="col-9 mt-4">
              <div>
                <span style="font-weight: bold;" class="box-margin-left">{{ vehicleDisplayInfo.registrationNumber }}</span>
              </div>
            </div>
            <div class="col-3 mt-4">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t('message.label_measured_data_details_registered_date') }}</span>
              </div>
            </div>
            <div class="col-9 mt-4">
              <div>
                <span style="font-weight: bold;" class="box-margin-left">{{ currentDate }}</span>
              </div>
            </div>
            <div class="col-3 mt-4 mb-2">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t('message.label_measured_data_details_registrants') }}</span>
              </div>
            </div>
            <div class="col-9 mt-4 mb-2">
              <div>
                <span style="font-weight: bold;" class="box-margin-left">{{ userInfo.userName }}</span>
              </div>
            </div>
            <div class="col-12">
              <hr class="hr-line-100">
            </div>
            <div class="col-3 mt-2">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t('individual.header_customize_name') }}</span>
              </div>
            </div>
            <div class="col-9 mt-2">
              <div>
                <span style="font-weight: bold;" class="box-margin-left">{{ $t(getSystemResourceKey) }}</span>
              </div>
            </div>
            <div class="col-3 my-4">
              <div class="mt-1 text-left align-middle">
                <span class="box-margin-right">{{ $t('individual.common_label_description') }}</span>
              </div>
            </div>
            <div class="col-9 my-4">
              <div class="text-left align-middle">
                <b-form-group>
                  <b-form-input v-model="description" :placeholder="$t('individual.label_save_settings_placeholder')" type="text"
                    size="sm" autocomplete="off" />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </ShowLessBOverlay>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="cancelSaveSettings" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="saveSettings" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>