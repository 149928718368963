<b-modal v-model="modal_requirement_096" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 実行中 -->
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 一覧表示 -->
      <template v-if="phase === 'listView' || phase === 'nodata'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 詳細FD表示 -->
      <template v-if="phase === 'detailedFd'">
        {{ $t('message.common_title_infomation') }}
      </template>
      <!-- プロジェクトデータ保存 -->
      <template v-if="phase === 'projectData'">
        {{ $t('message.label_vehicle_dtc_note_modal_note') }}
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行中 -->
        <template v-if="phase === 'start'"></template>
        <!-- 一覧表示 -->
        <template v-if="phase === 'listView'">
          <custom-table useStripeStyle hasTabs :items="lksDisplayInfo" :columns="tableColumns" useSort
            currentSortkey="status" initSortDir="asc" class="mt-2 ml-2" type="simple" usePopup rowSelect
            @rowButtonClicked="rowButtonClickEvent">
            <template v-slot:rowButton>{{ $t(`individual.button_fd`) }}</template>
          </custom-table>
        </template>
        <!-- 履歴データ無し表示 -->
        <template v-else-if="phase === 'nodata'">
          <div v-html="$t(`individual.label_history`)"></div>
        </template>
        <!-- 詳細FD表示 -->
        <template v-if="phase === 'detailedFd'">
          <div class="row ml-1">
            <div class="ml-2">
              <label>{{ statusLabel }}</label>
            </div>
            <div class="ml-2">
              <label style="font-weight: bold;">{{ statusValue }}</label>
            </div>
          </div>
          <custom-table class="detailedFd mt-2 ml-2" useStripeStyle hasTabs :items="displayFdInfo"
            :columns="tableFdColumns" useSort currentSortkey="did" initSortDir="asc" type="simple" usePopup>
          </custom-table>
        </template>

        <!-- プロジェクトデータ保存 -->
        <template v-if="phase === 'projectData'">
          <div class="row">
            <div class="col-12">
              <label class="sub-line-title-style"
                v-html="$t('message.label_vehicle_dtc_note_modal_information')"></label>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_vin')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ vin }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_vehicle')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ vehicleName }}</label>
                </div>
              </div>
            </div>
            <div v-if="hideRegistrationNumber" class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_number')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ registrationNumber }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr class="hr-line-100">
              <div>
                <label class="sub-line-title-style" v-html="$t('message.label_vehicle_dtc_note_modal_project')"></label>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_project_id')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ displayProjectID }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1 mt-2">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_comment')"></label>
                </div>
                <div class="col-9">
                  <b-form-textarea type="text" v-model="diagnoseComment" size="sm" rows="3"
                    :state="isAlert.diagnoseComment.valid">
                    {{ diagnoseComment }}
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ $t(isAlert.diagnoseComment.msg, [isAlert.diagnoseComment.length])
                    }}</b-form-invalid-feedback>
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr class="hr-line-100">
              <div>
                <label class="sub-line-title-style"
                  v-html="$t('message.label_vehicle_dtc_note_modal_measured_data')"></label>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_function_name')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ $t(functionName) }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-form-group>
                <div class="row ml-1">
                  <div class="col-3">
                    <label v-html="$t('message.label_vehicle_dtc_note_modal_data_name')"></label>
                    <span class="input-attention-required ml-1" v-html="$t('message.alert_vinz_input_required')"></span>
                  </div>
                  <div class="col-9">
                    <b-form-input type="text" v-model="dataName" size="sm" :state="isAlert.dataName.valid">
                      {{ dataName }}
                    </b-form-input>
                    <b-form-invalid-feedback>{{ $t(isAlert.dataName.msg, [isAlert.dataName.length])
                      }}</b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>
            </div>
            <div class="col-12">
              <div class="row ml-1 mt-2">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_note')"></label>
                </div>
                <div class="col-9">
                  <b-form-textarea type="text" v-model="memo" size="sm" rows="3" :state="isAlert.memo.valid">
                    {{ memo }}
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ $t(isAlert.memo.msg, [isAlert.memo.length]) }}</b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
              v-html="$t(`individual.error_communication_error`)"></div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 一覧表示 -->
      <template v-if="phase === 'listView'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="closeWizard" class="mx-2 mt-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="screenMovement" class="mx-2 mt-2">
          {{ $t('message.common_button_save') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="modeChange" :class="['mx-2 mt-2', checkButtonClass]">
          {{ displayModeInfo }}
        </b-button>
      </template>
      <!-- 履歴データ無し表示 -->
      <template v-if="phase === 'nodata'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2 mt-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="Secondary" class="btn btn-secondary mx-2 mt-2" disabled>
          {{ $t('message.common_button_save') }}
        </b-button>
        <b-button size="sm" variant="primary" :class="['mx-2 mt-2', checkButtonClass]" disabled>
          {{ displayModeInfo }}
        </b-button>
      </template>
      <!-- 詳細FD表示 -->
      <template v-if="phase === 'detailedFd'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="back" class="mx-2">
          {{ $t('message.common_button_back') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="csvExport" class="mx-2">
          {{ $t('message.file_export') }}
        </b-button>
      </template>
      <!-- プロジェクトデータ保存 -->
      <template v-if="phase === 'projectData'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="callListView" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="callSaveEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>