<b-modal
  v-model="modal_requirement_113"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' ">
        {{ $t(`individual.common_title_execution`) }}
      </template>
      <template v-if="phase === 'selection' ">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'error' ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- ファイル選択 -->
        <template v-if="phase === 'selection'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`individual.label_show_save`) }}
            </div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_113_TXT_1`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- フォルダ選択 -->
      <template v-if="phase === 'selection'">
        <b-button size="sm" variant="primary" @click="onSaveClicked" class="mx-2">
          {{ $t(`individual.button_work_support_select`) }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>