<div>
  <!-- 設定保存ダイアログ -->
  <b-modal v-model="modal_requirement_037" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
    no-close-on-backdrop no-close-on-esc centered no-stacking>
    <template v-slot:header>
      <div class="text-center w-100">
        <template v-if="phase === 'start'">
          {{ $t('individual.common_title_execution') }}
        </template>
        <template
          v-if="phase === 'execute' || phase === 'warn' || phase === 'displayPinNumber' || phase === 'inputPinNumber'">
          {{ $t('message.common_title_confirmation') }}
        </template>
        <template v-if="phase === 'failed' || phase === 'errorPinNumber'">
          {{ $t('message.common_title_error') }}
        </template>
      </div>
    </template>

    <template v-slot:default>
      <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
        <div class="container-fluid" style="min-height: 140px;">
          <!-- 通信画面 -->
          <template v-if="phase === 'start'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message"></div>
                </div>
              </div>
            </div>
          </template>
          <!--選択 -->
          <template v-if="phase === 'execute'">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message">
                    <b-form-select class="form-control" v-model="selectedMode" :options="modeList"
                      :select-size="6"></b-form-select>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- データ再登録警告表示 -->
          <template v-if="phase === 'warn'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.label_re-registration_warning`) }}</div>
            </div>
          </template>
          <!-- 暗証番号表示 -->
          <template v-if="phase === 'displayPinNumber'">
            <div class="w-100">
              <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_37_TXT_3`) }}</div>
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ fixedPinNumber }}
              </div>
            </div>
          </template>
          <!-- 暗証番号入力 -->
          <template v-if="phase === 'inputPinNumber'">
            <div class="w-100">
              <div class="row no-gutters">
                <div class="col-4 offset-4">
                  <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_37_TXT_4`) }}</div>
                  <div class="text-left align-middle">
                    <b-form-group>
                      <b-form-input v-model="pinNumber" :state="validatePinNumber && !validatePinNumber.invalid"
                        type="text" size="sm" autocomplete="off">
                      </b-form-input>
                      <b-form-invalid-feedback v-if="validatePinNumber">{{ validatePinNumber.msg
                        }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 暗証番号入力エラー -->
          <template v-if="phase === 'errorPinNumber'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.REQ_WS_37_TXT_13`) }}</div>
            </div>
          </template>
          <!-- 異常終了(エラー) -->
          <template v-if="phase === 'failed'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`individual.error_communication_error`) }}</div>
            </div>
          </template>
        </div>
      </ShowLessBOverlay>
    </template>

    <template v-slot:footer>
      <div class="w-100 text-center ">
        <!--選択 -->
        <template v-if="phase === 'execute'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitLogic(0)" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" variant="primary" :disabled="isLoading || !selectedMode" @click="selectMode" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- データ再登録警告表示 -->
        <template v-if="phase === 'warn'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitLogic(0)" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="displayPinNumber" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 暗証番号表示 -->
        <template v-if="phase === 'displayPinNumber'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="inputPinNumber" class="mx-2">
            {{ $t('individual.label_input') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading || fixedPinNumber === ''" variant="primary" @click="writeData"
            class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 暗証番号入力 -->
        <template v-if="phase === 'inputPinNumber'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="displayPinNumber" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="pinNumber = ''" class="mx-2">
            {{ $t('message.common_button_clear') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="validateInputPinNumber" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 暗証番号入力エラー -->
        <template v-if="phase === 'errorPinNumber'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitLogic(1)" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="displayPinNumber" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitLogic(1)" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
      </div>
    </template>
  </b-modal>

  <!-- 実行用別モーダル -->
  <component v-if="currentWizard" @close-wizard="closeWizard" :i18nWsKey="i18nWsKey" :workSupportId="workSupportId"
    :is="currentWizard">
  </component>
</div>