<div class="container-fluid individual-data-monitor">
  <div v-if="!showSelect" class="memo-btn">
    <b-button
      class="mt-n5 ml-n4"
      variant="primary"
      size="sm"
      @click="openMemo"
      :disabled="btnDisabled.memo"
    >
      {{ $t('message.button_vehicle_dtc_note_modal') }}
    </b-button>
  </div>

  <data-monitor
    class="{'dm-area': showSelect}"
    ref="data_monitor"
    :showSelect="showSelect"
    :inExecution="inExecution"
    :inPlaying="inPlaying"
    :measureId="measureId"
    :isUseCache="isUseCache"
    :isSpecial="isSpecial"
    @toggle-show-select="toggleShowSelect"
    @toggle-execute="toggleExecute"
    @toggle-playing="togglePlaying"
    @change-measure-id="changeMeasureId"
  />

  <!-- 計測画面の画面下部ボタン -->
  <div v-if="!showSelect" class="bottom-btns">
    <b-button
      variant="outline-primary"
      :disabled="btnDisabled.back"
      @click="backSelect"
    >
      {{ $t("message.common_button_back") }}
    </b-button>
    <b-button
      v-if="inExecution"
      variant="primary"
      :disabled="btnDisabled.execute"
      @click="stopMonitor"
    >
      {{ $t('individual.button_data_monitor_stop') }}
    </b-button>
    <b-button
      v-else
      variant="primary"
      :disabled="btnDisabled.execute"
      @click="startMonitor"
    >
      {{ $t('individual.button_data_monitor_measure') }}
    </b-button>
  </div>

  <!-- メモ画面 -->
  <b-modal
    v-model="modalMemo"
    size="lg"
    header-bg-variant="secondary"
    header-text-variant="light"
    no-stacking
    no-close-on-backdrop
    no-close-on-esc
    centered
    scrollable
  >
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>

    <memo-page
      v-if="modalMemo"
      ref="memo"
      :projectId="projectId"
      :measureId="measureId"
      :functionNameKey="navItemLabel"
      :destinationAPI="destinationAPI"
      @closeNoteModal="closeNoteModal"
    />

    <template v-slot:footer>
      <button
        type="button"
        v-on:click="memoClear()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill"
      >
        {{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="modalMemo = false"
          class="ml-2 mr-2"
        >
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="memoSave()"
          class="ml-2 mr-2"
        >
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>
