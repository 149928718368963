export default {
  name: 'dataMonitorMeasureListItem',
  props: {
    index: {
      type: Number
    },

    source: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  methods: {
    getRowStyle(index) {
      let idx = index;
      if (!idx) {
        idx = 0;
      }

      return idx % 2 === 0 ? 'data-monitor-border-row' : 'data-monitor-non-border-row';
    },
  },
};
