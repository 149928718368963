import error from "../../../common/nodejs/errors";

// AT表データ
export const COMP_ERR = "status_error";

// AT表データ
export const AT_TABLE = {
  SP5: {
    STEP: "01",
    MAX: "1F",
    MIN: "00",
    INIT: "00"
  },
};

// AT項目翻訳ファイルの親子関係があるデータのタイトル/説明データID
export const I18N_PARENT_ID = "999998";

/** API type定義 */
export const API_TYPE = {
  READ: "read",
  STANDBY: "standby",
  START: "start",
  STOP: "stop",
  TERMINATE: "terminate",
  ERR_TERMINATE: "errorTerminate",
};

/** 動作状態の定義 */
export const FLOW = {
  INIT: "init", // 開始状態●
  STANDBY: "standBy", // 待機
  PRE_EXECUTE: "preExe", // 実行待ち
  EXECUTE: "execute", // 実行
  CHECK_EXE: "checkExecute", // 実行監視
  STOP: "stop", // 停止
  CHECK_STOP: "checkStop", // 停止監視
  END: "end", // 終了状態◎
  ERR: "error", // 共通エラー
  ERR_COMMUNICATE: "communicationError", // 通信エラー
  ERR_STATUS: "statusError", // STATUSエラー
  ERR_NRC: "nrcError", // NRCエラー
};

/** AT実行API Completed時のres_type */
export const RES_TYPE = {
  OK: "ok",
  NO: "no",
  NG: "ng",
};

/** AT実行API 監視状態 */
export const MNTR_STATUS = {
  /** 継続 */
  IN_PROGRESS: "in_progress",
  /** 終了 */
  COMPLETE: "complete",
};

/** NRCエラーコード */
export const NRC_CODE = {
  X11: "11",
  X12: "12",
  X22: "22",
  X31: "31",
};

/** データタイプの定義 */
export const DATA_TYPE = {
  VAL: "Value",
  HEX: "HEX",
  FLG: "FLG",
};

// エラーメッセージの種類(valueはエラーコード)
export const ERR_KEY = {
  // 共通エラー
  COMMON: [
    error.CLOUD_CONNECTOR_VERSION_WRONG,
    error.CLOUD_CONNECTOR_NOT_ACTIVE,
    error.CLOUD_CONNECTOR_USEDBY_OTHER,
    error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED,
    error.CLOUD_CONNECTOR_INQUIRY_RSP_WRONG,
    error.CLOUD_CONNECTOR_INQUIRY_VEHICLE_ALREADY_UPDATED,
    error.CLOUD_CONNECTOR_INQUIRY_INVALID_TARGET_ECU,
    error.CLOUD_CONNECTOR_INQUIRY_RSP_NOT_RECEIVED,
    error.CLOUD_CONNECTOR_VEHICLE_COMMUNICATION_ERROR,
    error.CLOUD_CONNECTOR_INTERNAL_ERROR,
    error.CLOUD_CONNECTOR_USER_NOT_PAIRED,
    error.CLOUD_CONNECTOR_PFC_FILE_DOWNLOAD_FAILED,
    error.CLOUD_CONNECTOR_REPRO_EXECUTION_INPROGRESS,
    error.CLOUD_CONNECTOR_INVALID_J2534_DEVICE,
    error.CLOUD_CONNECTOR_SEQUENCE_APP_NOT_FOUND,
    error.CLOUD_CONNECTOR_SEQUENCE_APP_INIT_FAILED,
    error.CLOUD_CONNECTOR_REPRO_EXECUTE_FAILED,
    error.CLOUD_CONNECTOR_J2534_DEVICE_OPEN_FAILED,
    error.CLOUD_CONNECTOR_J2534_DEVICE_CONNECT_FAILED,
    error.CLOUD_CONNECTOR_DISCONNECT_NETWORK,
    error.CLOUD_CONNECTOR_READ_RXSWIN_FAILED,
    error.CLOUD_CONNECTOR_READ_RXSWIN_FAILED_2,
    error.CLOUD_CONNECTOR_UPDATE_RXSWIN_FAILED,
    error.CLOUD_CONNECTOR_UPDATE_RXSWIN_FAILED_2,
    error.CLOUD_CONNECTOR_READ_RXSWIN_FAILED_BEFORE_UPDATE,
  ],
  // 通信エラー
  COMMUNICATION: [],
  // 初期化通信エラー
  INIT: [],
  // STATUSエラー
  STATUS: [],
  // NRCエラー
  NRC: [],
  // 停止NRCエラー
  STOP_NRC: [],
};

/** エラータイプ */
export const ERR_TYPE = {
  /** 通信エラー */
  COMMUNICATE: "communicationError",
  /** STATUSエラー */
  STATUS: "statusError",
  /** NRCエラー */
  NRC: "nrcError",
};

export default {
  COMP_ERR,
  AT_TABLE,
  I18N_PARENT_ID,
  API_TYPE,
  FLOW,
  RES_TYPE,
  MNTR_STATUS,
  NRC_CODE,
  DATA_TYPE,
  ERR_KEY,
  ERR_TYPE,
};
