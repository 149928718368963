<div class="container-fluid pt-2 pb-0 px-0 max-height data-monitor-measure">
  <div class="row mx-0 max-height">
    <div class="col-12 px-0 max-height data-monitor-measure__container">
      <b-tabs>
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="currentTab = index"
          :active="currentTab === index"
          :title="$t(tab.name)"
        >
        </b-tab>
      </b-tabs>
      <div class='tab-content' :class="{'no-scroll': currentTab !== tabSplit}">
        <transition name="fade">
          <div v-if="currentTab === tabList" key="tab1" class="list-area">
          <measure-list
            :selectedFunctionList="selectedFunctionList"
            :executionList="executionList"
            :funcValues="funcValues"
            :measurementList="measurementList"
            :measurementTimes="measurementTimes"
            :measurementUuid="measurementUuid"
            :windowSize="windowSize"
          />
        </div>
        </transition>

        <transition name="fade">
          <div key="tab2" class="graph-area__container" v-show="currentTab === tabSplit">
            <div v-for="(k, i) in selectedFunctionKeys()" :key="i" class="graph-area">
              <graph-element
                :id="splitGraphId(i + 1)"
                :xaxes-info="setXAxesInfo()"
                :yaxes-info="setYAxesInfo(tabSplit, i)"
                :index="i" />
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div key="tab3" class="graph-area__container" v-show="currentTab === tabUnion">
            <div class="graph-area">
              <graph-element
                :id="unionGraphId()"
                :xaxes-info="setXAxesInfo()"
                :yaxes-info="setYAxesInfo(tabUnion)" />
            </div>
          </div>
        </transition>
      </div>

      <div v-show="currentTab === tabList" class="seek-bars mb-1 pt-1">
        <div class="seek-bar-container">
          <div class="seek-bar">
            <time-line :id="listTimeLineId()"
              ref = "timeLineSeekBar"
              :start-time = "startTimeVal()"
              :end-time = "endTimeVal()"
              no-range = true
              disable = true />
          </div>
          <span class="time-line-indicator">{{currentTimeStr}}</span>
          <b-button
            class="mark-btn text-right"
            squared
            variant="primary btn-sm"
            @click="openMarkModal(tabList)"
            no-range="true"
            :disabled="btnDisabled.marker"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="3 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <path
                  d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z"
                  fill-rule="evenodd"
                />
              </g>
            </svg>
          </b-button>
        </div>
      </div>

      <div v-show="currentTab === tabSplit" class="seek-bars mb-1 pt-1">
        <div class="seek-bar-container">
          <div class="seek-bar">
            <time-line :id="splitTimeLineId()"
              ref = "splitGraphTimeLineSeekBar"
              :start-time = "startTimeVal()"
              :end-time = "endTimeVal()"
              disable = true />
          </div>
          <span class="time-line-indicator">{{currentTimeStr}}</span>
          <b-button
            class="mark-btn text-right"
            squared
            variant="primary btn-sm"
            @click="openMarkModal(tabSplit)"
            :disabled="btnDisabled.marker"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="3 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <path
                  d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z"
                  fill-rule="evenodd"
                />
              </g>
            </svg>
          </b-button>
        </div>
      </div>

      <div v-show="currentTab === tabUnion" class="seek-bars mb-1 pt-1">
        <div class="seek-bar-container">
          <div class="seek-bar">
            <time-line :id="unionTimeLineId()"
              ref = "unionGraphTimeLineSeekBar"
              :start-time = "startTimeVal()"
              :end-time = "endTimeVal()"
              disable = true />
          </div>
          <span class="time-line-indicator">{{currentTimeStr}}</span>
          <b-button
            class="mark-btn text-right"
            squared
            variant="primary btn-sm"
            @click="openMarkModal(currentTab)"
            no-range="true"
            :disabled="btnDisabled.marker"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="3 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <path
                  d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z"
                  fill-rule="evenodd"
                />
              </g>
            </svg>
          </b-button>
        </div>
      </div>

      <div class="seek-btns">
        <b-button
          id="dm-backLong"
          squared
          variant="primary btn-sm"
          :disabled="btnDisabled.seekBtns"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g>
                <polygon
                  points="17.59,18 19,16.59 14.42,12 19,7.41 17.59,6 11.59,12"
                />
                <polygon
                  points="11,18 12.41,16.59 7.83,12 12.41,7.41 11,6 5,12"
                />
              </g>
            </g></svg>
        </b-button>
        <b-button
          id="dm-backShort"
          squared
          variant="primary btn-sm"
          :disabled="btnDisabled.seekBtns"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
            /></svg>
        </b-button>
        <!--<b-button
          id="dm-stop"
          squared
          variant="primary btn-sm"
          :disabled="btnDisabled.stop"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 6h12v12H6z" /></svg
        ></b-button>
        <b-button
          id="dm-play"
          squared
          variant="primary btn-sm"
          :disabled="btnDisabled.play"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M8 5v14l11-7z" /></svg
        ></b-button>-->
        <b-button
          id="dm-forwardShort"
          squared
          variant="primary btn-sm"
          :disabled="btnDisabled.seekBtns"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
            /></svg>
        </b-button>
        <b-button
          id="dm-forwardLong"
          squared
          variant="primary btn-sm"
          :disabled="btnDisabled.seekBtns"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <g><rect fill="none" height="24" width="24" /></g>
            <g>
              <g>
                <polygon
                  points="6.41,6 5,7.41 9.58,12 5,16.59 6.41,18 12.41,12"
                />
                <polygon
                  points="13,6 11.59,7.41 16.17,12 11.59,16.59 13,18 19,12"
                />
              </g>
            </g></svg>
        </b-button>
      </div>
      <processing-view :processing="processing"></processing-view>
    </div>
  </div>

  <b-modal v-model="modalMark" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t("individual.label_data_monitor_marker") }}
      </div>
    </template>
    <mark-page 
      v-if="modalMark"
      ref="modal_mark"
      :measurementUuid="measurementUuid"
      :apiToken="apiToken"
      :elapsedTime="elapsedTime"
      :markerUuid="markerUuid"
      :endpoint="endpoint"
      />
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary" @click="modalMark = false" class="ml-2 mr-2">
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button v-if="showDeleteButton" size="sm" variant="outline-primary" @click="markDelete" class="ml-2 mr-2">
          {{ $t("individual.button_data_monitor_delete") }}
        </b-button>
        <b-button size="sm" variant="primary" @click="markUpdate" class="ml-2 mr-2">
          {{ $t("message.common_button_save") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</div>