<b-modal v-model="modal_special_requirement_ES6" size="lg" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'history-view' || 
        phase === 'store-history-view'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'start' || 
        phase === 'alert-history-data'">
        {{ $t('message.common_title_warning') }}
      </template>
      <template v-if="phase === 'read-history-data'">
        {{ $t(`individual.common_title_execution`) }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 警告表示 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="work-support-message">{{ $t(`individual.label_start_header_ES6`) }}</div>
              </div>
              <div class="text-left align-middle mt-5">
                <div class="work-support-message" v-html="$t('individual.label_start_ES6')"></div>
              </div>
              <div class="work-support-message mt-3">{{ $t(`individual.label_start_confirm_ES6`) }}</div>
              <b-form-checkbox v-model="confirmChecked" class="w-100 mt-3" @click="!confirmChecked">
                <div class="work-support-message">{{ $t(`individual.label_confirm_yes`) }}</div>
              </b-form-checkbox>
              <div class="work-support-message mt-3" v-html="$t('individual.label_start_agreement_ES6')"></div>
              <b-form-checkbox v-model="agreementChecked" class="w-100 mt-3" @click="!agreementChecked">
                <div class="work-support-message">{{ $t(`individual.label_confirm_yes`) }}</div>
              </b-form-checkbox>
            </div>
          </div>
        </template>
        <!-- 履歴情報表示 -->
        <template v-if="phase === 'history-view'">
          <custom-table useStripeStyle hasTabs useSort :items="displayHistoryInfo" :columns="historyInfoTableColumns"
            class="mt-2 ml-2" type="simple" rowSelect @rowClicked="historyRowClick" currentSortkey="displayName"
            initSortDir="asc"></custom-table>
        </template>
        <!-- 保存履歴表示 -->
        <template v-if="phase === 'store-history-view'">
          <custom-table useStripeStyle hasTabs useSort :items="displayStoreHistoryInfo"
            :columns="storeHistoryInfoTableColumns" class="mt-2 ml-2" type="simple" rowSelect
            @rowClicked="historyStoreRowClick" currentSortkey="store_item" initSortDir="asc"></custom-table>
        </template>
        <!-- 履歴データ読み出し警告 -->
        <template v-if="phase === 'alert-history-data'">
          <div class="work-support-message" v-html="$t(`individual.label_read_history_alert`, [estimatedTime])"></div>
        </template>
        <!-- 履歴データ読み出し -->
        <template v-if="phase === 'read-history-data'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="container-fluid mt-2 mb-2">
                <custom-Progress :value="progress" :max="progressMax"
                  :message="$t(`individual.label_phase_message`) + `(${doneFile}/${fileAmount})`" />
              </div>
            </div>
          </div>
        </template>
        <!-- 成功表示 -->
        <template v-if="phase === 'success'">
          <div class="work-support-message" v-html="$t('individual.label_success_ES6')"></div>
        </template>
        <!-- 失敗表示 -->
        <template v-if="phase === 'failed'">
          <div class="work-support-message">{{ $t(`individual.label_failed_ES6`) }}</div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center">
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || isDisabledAgreementConfirm" variant="primary"
          @click="screenMovement" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'history-view'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || isDisableButton" variant="primary" @click="selectHistory"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'store-history-view'">
        <div class="d-flex justify-content-end flex-wrap">
          <b-button size="sm" :disabled="isLoading || isDisableReleaseButton" variant="outline-primary"
            @click="releaseRow" class="mx-2 mt-2">
            {{ $t('individual.common_button_release') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading || isDisableSelectButton || !isDisableReleaseButton" variant="primary"
            @click="selectRow" class="mx-2 mt-2">
            {{ $t('individual.button_work_support_select') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="selectAllRow" class="mx-2 mt-2">
            {{ $t('individual.common_button_all_select') }}
          </b-button>
        </div>
        <br />
        <div class="d-flex justify-content-center">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="backTo('history-view')"
            class="mx-2">
            {{ $t('message.common_button_back') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading || isDisableOkButton" variant="primary" @click="saveHistory"
            class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
      <template v-if="phase === 'alert-history-data'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="backTo('store-history-view')"
          class="mx-2">
          {{ $t('message.common_button_back') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="execute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'error'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>